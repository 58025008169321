import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { OperatorRestViolationAcknowledgementData, OperatorRestViolationDto, OperatorRestViolationExplanationData, OperatorRestViolationReason, OperatorTimesheetEndpoint } from "../../operator-timesheet.service";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from "@angular/forms";
import { delayAtLeast, ErrorService, hasText, SpinnerButtonComponent } from "common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";

@Component({
    templateUrl: "./operator-timesheet-edit-violation.component.html",
    styleUrls: ["./operator-timesheet-edit-violation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TranslateModule,
    ],
})
export class OperatorTimesheetEditViolationComponent {

    private readonly violationId: number;
    private readonly model: MinimalEditViolationModel;

    readonly viewData = signal<OperatorTimesheetEditViolationComponentViewData | null>(null);
    readonly saving = signal(false);
    readonly reasonOptions: ReasonOption[];

    constructor(operatorTimesheetEndpoint: OperatorTimesheetEndpoint,
                private readonly dialogRef: MatDialogRef<OperatorTimesheetEditViolationComponent>,
                private readonly errorService: ErrorService,
                translateService: TranslateService,
                @Inject(MAT_DIALOG_DATA) data: OperatorTimesheetEditViolationComponentParams) {

        this.model = data.model;
        this.violationId = data.violationId;
        this.reasonOptions = [
            reasonOption(OperatorRestViolationReason.TRAFFIC, translateService),
            reasonOption(OperatorRestViolationReason.WEATHER_CONDITIONS, translateService),
            reasonOption(OperatorRestViolationReason.PERSONNEL, translateService),
            reasonOption(OperatorRestViolationReason.EQUIPMENT, translateService),
            reasonOption(OperatorRestViolationReason.ROTATION_SYSTEM, translateService),
            reasonOption(OperatorRestViolationReason.OTHER, translateService)
        ];

        operatorTimesheetEndpoint.findRestViolationForEditing(this.violationId)
            .pipe(delayAtLeast(300))
            .subscribe({
                next: dto => {
                    this.viewData.set({
                        dto,
                        form: createForm(dto)
                    });
                },
                error: e => errorService.showLoadError(e)
            });
    }

    acknowledgeButtonTextKey(vd: OperatorRestViolationDto): string {
        return vd.acknowledged === null ? "timesheet.acknowledge" : "common.save";
    }

    save(form: FormGroup<RestViolationForm>): void {
        const formData = form.value;
        const updateData: OperatorRestViolationExplanationData = {
            reason: formData.reason!,
            notes: formData.notes!
        };
        this.saving.set(true);
        this.model.updateRestViolation(this.violationId, updateData).subscribe({
            next: () => {
                this.dialogRef.close(true);
            },
            error: e => {
                this.saving.set(false);
                this.errorService.showUpdateError(e);
            }
        });
    }

    acknowledge(form: FormGroup<RestViolationForm>): void {
        const formData = form.value;
        const acknowledgementData: OperatorRestViolationAcknowledgementData = {
            notes: formData.notes!,
            selfInflicted: formData.selfInflicted!
        };

        this.saving.set(true);
        this.model.acknowledgeRestViolation(this.violationId, acknowledgementData).subscribe({
            next: () => {
                this.dialogRef.close(true);
            },
            error: e => {
                this.saving.set(false);
                this.errorService.showUpdateError(e);
            }
        });
    }
}

export interface OperatorTimesheetEditViolationComponentParams {
    model: MinimalEditViolationModel;
    violationId: number;
}

export interface MinimalEditViolationModel {
    updateRestViolation(violationId: number, updateData: OperatorRestViolationExplanationData): Observable<void>;

    acknowledgeRestViolation(violationId: number, acknowledgeData: OperatorRestViolationAcknowledgementData): Observable<void>;
}

function reasonOption(reason: OperatorRestViolationReason, translateService: TranslateService): ReasonOption {
    return {
        value: reason,
        description: translateService.instant((`timesheet.violation_reason.${reason}`))
    };
}

function createForm(violation: OperatorRestViolationDto): FormGroup<RestViolationForm> {
    const reasonValidators = !violation.mayAcknowledge ? [Validators.required] : [];
    return new FormGroup({
        reason: new FormControl({value: violation.reason, disabled: violation.mayAcknowledge}, reasonValidators),
        notes: new FormControl(violation.notes, {nonNullable: true}),
        selfInflicted: new FormControl<boolean>(violation.selfInflicted, {nonNullable: true})
    }, {validators: restViolationCrossFieldValidator});
}

function restViolationCrossFieldValidator(control: AbstractControl): ValidationErrors | null {
    const form = control as FormGroup<RestViolationForm>;

    if (form.controls.reason.value === OperatorRestViolationReason.OTHER && !hasText(form.controls.notes.value))
        return {otherNeedsExplicitNote: true};

    return null;
}

interface OperatorTimesheetEditViolationComponentViewData {
    dto: OperatorRestViolationDto;
    form: FormGroup<RestViolationForm>;
}

interface ReasonOption {
    value: OperatorRestViolationReason;
    description: string;
}

interface RestViolationForm {
    reason: FormControl<OperatorRestViolationReason | null>;
    notes: FormControl<string>;
    selfInflicted: FormControl<boolean>;
}
