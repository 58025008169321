import { OperatorTaskType } from "../operator-timesheet.service";

// Duplication in OperatorTaskType.kt
export function hasTripDuration(type: OperatorTaskType): boolean {
    return isBoatTrip(type);
}

function isBoatTrip(type: OperatorTaskType): boolean {
    switch (type) {
        case OperatorTaskType.BOAT_TRIP_PILOTAGE:
        case OperatorTaskType.BOAT_TRIP_OTHER:
            return true;

        case OperatorTaskType.CAR_TRIP_PILOTAGE:
        case OperatorTaskType.CAR_TRIP_OTHER:
        case OperatorTaskType.MAINTENANCE_EQUIPMENT:
        case OperatorTaskType.MAINTENANCE_BUILDINGS:
        case OperatorTaskType.NON_WORK_TRAVEL:
        case OperatorTaskType.OTHER_WORK:
        case OperatorTaskType.STATION_MEETING:
            return false;
    }
}
