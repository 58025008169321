@if (viewData(); as vd) {
    <h2 mat-dialog-title>{{ 'timesheet.edit_rest_violation' | translate }}</h2>
    <mat-dialog-content>
        <div class="form-container">
            <mat-radio-group [formControl]="vd.form.controls.reason" class="reason-group">
                @for (reason of reasonOptions; track $index) {
                    <mat-radio-button class="reason-option" [value]="reason.value">{{ reason.description }}</mat-radio-button>
                }
            </mat-radio-group>
            <mat-form-field class="mb-2">
                <mat-label>{{ 'timesheet.notes' | translate }}</mat-label>
                <textarea [formControl]="vd.form.controls.notes" matInput rows="3"></textarea>
            </mat-form-field>

            @if (vd.dto.mayAcknowledge) {
                <mat-checkbox [formControl]="vd.form.controls.selfInflicted">{{ "timesheet.violation_unfounded" | translate }}</mat-checkbox>
            }

            @if (vd.form.errors?.['otherNeedsExplicitNote'] && (vd.form.touched || vd.form.dirty)) {
                <div class="cross-validation-error-message">{{ 'timesheet.other_reason_needs_notes' | translate }}</div>
            }

            @if (!vd.dto.mayAcknowledge) {
                @if (vd.dto.selfInflicted) {
                    <div class="mat-body note">{{ 'timesheet.superior_marked_as_unfounded' | translate }}</div>
                }
                <div class="mat-body note">{{ "timesheet.send_rest_violation_note" | translate }}</div>
            }
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ "common.cancel" | translate }}</button>

        @if (vd.dto.mayAcknowledge) {
            <app-spinner-button [disabled]="vd.form.invalid" (spinnerClick)="acknowledge(vd.form)" [active]="saving()">{{ acknowledgeButtonTextKey(vd.dto) | translate }}</app-spinner-button>
        } @else {
            <app-spinner-button [disabled]="vd.form.invalid" (spinnerClick)="save(vd.form)" [active]="saving()">{{ "timesheet.send_violation" | translate }}</app-spinner-button>
        }
    </mat-dialog-actions>
}
