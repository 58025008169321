@if (data(); as data) {
    <mat-card appearance="outlined">
        @if (data !== "load-failed") {

            <!-- Print specialized for salary archive, no localization -->
            @if (data.printData; as printData) {
                <div class="timesheet-print print-only">
                    <table class="compensated-days">
                        <tr>
                            <th>Pvm</th>
                            <th>Veneelläajo</th>
                            <th>Km</th>
                            <th>6h</th>
                            <th>10h</th>
                            <th>Koulutus</th>
                            <th>Ylityö (50%)</th>
                            <th>Ylityö (75%)</th>
                            <th>Lisätiedot</th>
                        </tr>
                        @for (g of printData.groups; track g.start.toEpochDay()) {
                            <tr>
                                <td>{{ g.start | helsinkiDate:'dd.MM.' }}</td>
                                <td>
                                    <app-minutes-duration [minutes]="g.compensatedBoatMinutesWithinPeriodGroup" zeroValue=""/>
                                </td>
                                <td>{{ g.compensatedKilometers > 0 ? g.compensatedKilometers : "" }}</td>
                                <td>{{ g.shortTrips > 0 ? g.shortTrips : "" }}</td>
                                <td>{{ g.longTrips > 0 ? g.longTrips : "" }}</td>
                                <td>
                                    <app-minutes-duration [minutes]="g.trainingMinutes" zeroValue=""/>
                                </td>
                                <td>
                                    <app-minutes-duration [minutes]="g.overtime50Minutes" zeroValue=""/>
                                </td>
                                <td>
                                    <app-minutes-duration [minutes]="g.overtime75Minutes" zeroValue=""/>
                                </td>
                                <td>{{ g.allPeriodNotes }}</td>
                            </tr>
                        }
                        <tr>
                            <td>Yhteensä</td>
                            <td>
                                <app-minutes-duration [minutes]="data.totals.compensatedBoatMinutes" zeroValue="-"/>
                            </td>
                            <td>{{ data.totals.compensatedKilometers }}</td>
                            <td>{{ data.totals.shortTrips }}</td>
                            <td>{{ data.totals.longTrips }}</td>
                            <td>
                                <app-minutes-duration [minutes]="data.totals.trainingMinutes" zeroValue="-"/>
                            </td>
                            <td>
                                <app-minutes-duration [minutes]="data.totals.overtime50Minutes" zeroValue="-"/>
                            </td>
                            <td>
                                <app-minutes-duration [minutes]="data.totals.overtime75Minutes" zeroValue="-"/>
                            </td>
                            <td></td>
                        </tr>
                    </table>

                    <h3>Komennukset</h3>

                    @if (data.printData.secondments.length > 0) {
                        <table>
                            @for (secondment of data.printData.secondments; track $index) {
                                <tr>
                                    <td>{{ secondment.range.start | helsinkiDate: 'dd.MM.' }}&ndash;{{ secondment.range.end | helsinkiDate: 'dd.MM.' }}</td>
                                    <td>{{ secondment.stationName }}</td>
                                </tr>
                            }
                        </table>
                    } @else {
                        <div>Ei komennuksia</div>
                    }

                    <h3>Poissaolot</h3>

                    @if (data.printData.absences.length > 0) {
                        <table>
                            @for (abs of data.printData.absences; track $index) {
                                <tr>
                                    <td>{{ abs.start | helsinkiDate: 'dd.MM.' }}&ndash;{{ abs.end | helsinkiDate: 'dd.MM.' }}</td>
                                    <td>{{ abs.description }}</td>
                                    <td>{{ abs.days }} pv</td>
                                </tr>
                            }
                        </table>
                    } @else {
                        <div>Ei poissaoloja</div>
                    }
                </div>
            }

            @if (data.groups.length > 0) {
                <div class="no-print">
                    <div class="day-columns day-columns-header">
                        <span class="day">{{ "timesheet.header_day" | translate }}</span>
                        <span class="work-time">{{ "timesheet.header_task" | translate }}</span>
                        <span class="boat-time">{{ "timesheet.header_boat_time" | translate }}</span>
                        <span class="allowance">{{ "timesheet.header_allowance" | translate }}</span>
                        <span class="kilometers">km</span>
                        <span class="trips">{{ "timesheet.header_trips" | translate }}</span>
                        <span class="training">{{ "timesheet.header_training" | translate }}</span>
                        <span class="overtime">{{ "timesheet.header_overtime" | translate }}</span>
                        <span class="info-icons"></span>
                    </div>

                    <mat-accordion [multi]="true" #accordion="matAccordion">
                        @for (group of data.groups; track group.start.toEpochDay()) {
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <div class="day-columns day-columns-data" [class.group-day]="group.groupName">
                                        <div class="day">
                                            @if (group.oneDayPeriod) {
                                                <time [matTooltip]="(group.start | helsinkiDate: 'dd.MM.') ?? ''">
                                                    <app-operator-timesheet-time [day]="group.start" [locale]="locale"/>
                                                </time>
                                            } @else {
                                                <span [matTooltip]="(group.start | helsinkiDate: 'dd.MM.') + ' - ' + (group.end | helsinkiDate: 'dd.MM.')">
                                                    <app-operator-timesheet-time [day]="group.start" [locale]="locale"/>
                                                    &ndash;
                                                    <app-operator-timesheet-time [day]="group.end" [locale]="locale"/>
                                                </span>
                                            }
                                        </div>
                                        <span class="shift-type" [matTooltip]="group.tyovuoroMerkkiKuvaus">{{ group.tyovuoroMerkki }}</span>
                                        <app-activity-minimap class="activity" [date]="group.start" [periods]="data.minimapPeriods"/>
                                        <span class="period-group-name">{{ group.groupName }}</span>
                                        <span class="work-time"><app-minutes-duration [minutes]="group.workMinutesWithinPeriodGroup"/></span>
                                        <span class="boat-time"><app-minutes-duration [minutes]="group.compensatedBoatMinutesWithinPeriodGroup"/></span>
                                        <span class="allowance">{{ formatDailyAllowance(group.dailyAllowanceFull, group.dailyAllowancePart) }}</span>
                                        @if (group.compensatedKilometers > 0 || group.uncompensatedKilometers > 0) {
                                            <span class="kilometers">
                                                <span>{{ group.compensatedKilometers }}</span>
                                                @if (group.uncompensatedKilometers > 0) {
                                                    <small>+{{ group.uncompensatedKilometers }}</small>
                                                }
                                            </span>
                                        }
                                        <span class="trips">{{ formatTrips(group) }}</span>
                                        <app-minutes-duration class="training" [minutes]="group.trainingMinutes" zeroValue=""/>
                                        <span class="overtime">
                                            @if (group.overtime50Minutes > 0) {
                                                <app-minutes-duration [minutes]="group.overtime50Minutes" suffix="(50%)"/>
                                            }
                                            @if (group.overtime75Minutes > 0) {
                                                <app-minutes-duration [minutes]="group.overtime75Minutes" suffix="(75%)"/>
                                            }
                                        </span>
                                        <span class="info-icons no-print">
                                            @if (group.hasErrors) {
                                                <mat-icon matTooltip="{{'timesheet.periods_with_errors_note' | translate}}" [color]="iconColor(group.supervisorActionNeeded)">error</mat-icon>
                                            }
                                            @if (group.hasNotes) {
                                                <mat-icon matTooltip="{{'timesheet.periods_with_notes_note' | translate}}" [color]="iconColor(group.supervisorActionNeeded)">message</mat-icon>
                                            }
                                        </span>
                                    </div>
                                </mat-expansion-panel-header>

                                @if (group.periods.length > 0) {
                                    <table class="default-table tasks">
                                        <thead>
                                        <tr>
                                            <th class="type">{{ "timesheet.header_task" | translate }}</th>
                                            <th class="start">{{ "timesheet.header_start" | translate }}</th>
                                            <th class="start">{{ "timesheet.header_end" | translate }}</th>
                                            <th class="duration">{{ "timesheet.header_duration" | translate }}</th>
                                            <th class="km">Km</th>
                                            <th class="notes">{{ "timesheet.header_notes" | translate }}</th>
                                            <th></th>
                                            <th class="actions"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            @for (period of group.periods; track period.id) {
                                                <tr>
                                                    <td class="type" [matTooltip]="period.typeName">
                                                        <div class="task-icons">
                                                            @for (icon of period.icons; track $index) {
                                                                <mat-icon [color]="iconColor(period.supervisorActionNeeded)">{{ icon }}</mat-icon>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        @if (!period.hideStart) {
                                                            <span>{{ period.range.start | helsinkiDate: 'HH:mm' }}
                                                                @if (period.showDateWithStartTime) {
                                                                    <small> ({{ period.range.start | helsinkiDate: 'dd.MM.' }})</small>
                                                                }
                                                            </span>
                                                        }

                                                        @if (period.tripRange?.start && (!period.tripStartImplicit || !period.tripRange!.start.equals(period.range.start))) {
                                                            <span class="trip-start" [class.implicit]="period.tripStartImplicit">→ {{ period.tripRange!.start | helsinkiDate: 'HH:mm' }}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        @if (!period.hideEnd) {
                                                            <span>{{ period.range.end | helsinkiDate:'HH:mm' }}
                                                                @if (period.showDateWithEndTime) {
                                                                    <small> ({{ period.range.end | helsinkiDate: 'dd.MM.' }})</small>
                                                                }
                                                            </span>
                                                        }
                                                    </td>
                                                    <td>
                                                        @if (!period.hideDuration) {
                                                            <app-minutes-duration [minutes]="rangeMinutes(period.range)"/>
                                                        }
                                                    </td>
                                                    <td class="km" [class.uncompensated]="!period.compensatedKilometers">
                                                        {{ period.kilometers }}
                                                    </td>
                                                    <td class="truncated">
                                                        <span class="truncated-content">
                                                            @if (hasErrors(period)) {
                                                                <span>
                                                                    @if (period.errors?.needsOvertimeNote) {
                                                                        <span class="error">{{ "timesheet.needs_overtime_note" | translate }} </span>
                                                                    }
                                                                    @if (period.errors?.missingTripPeriod) {
                                                                        <span class="error">{{ "timesheet.missing_trip_period" | translate }} </span>
                                                                    }
                                                                    @if (period.errors?.overlapping) {
                                                                        <span class="error">{{ "timesheet.overlap_warning" | translate }} </span>
                                                                    }
                                                                    @if (period.errors?.needsRestViolationReason) {
                                                                        <span class="error">{{ "timesheet.rest_violation_needs_resolving" | translate }} </span>
                                                                    }
                                                                    @if (period.errors?.hasWorkOnVacation) {
                                                                        <span class="error">{{ "timesheet.has_work_on_vacation" | translate }} </span>
                                                                    }
                                                                </span>
                                                            }
                                                            <span>{{ period.notes }}</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        @if (hasErrors(period) || period.notes) {
                                                            <button class="info-button" mat-icon-button (click)="showNotes(period)">
                                                                <mat-icon [color]="iconColor(hasErrors(period))">message</mat-icon>
                                                            </button>
                                                        }
                                                    </td>
                                                    <td class="actions no-print">
                                                        <span class="action-icons">
                                                            @if (period.editable) {
                                                                <button mat-icon-button title="{{'common.edit' | translate}}" (click)="open(period, data.editableDays)">
                                                                    <mat-icon>create</mat-icon>
                                                                </button>
                                                            }
                                                            @if (period.removable) {
                                                                <button mat-icon-button title="{{'common.remove' | translate}}" (click)="remove(period)">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>
                                                            }
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                } @else {
                                    <div class="day-without-periods">
                                        @if (group.oneDayPeriod) {
                                            {{ 'timesheet.no_periods_for_day' | translate }}
                                        } @else {
                                            {{ 'timesheet.no_periods_for_multiday' | translate }}
                                        }
                                    </div>
                                }

                                @if (group.editableRangeInGroup != null) {
                                    <mat-action-row class="no-print">
                                        <button mat-button (click)="newTask($event, group.start, data.editableDays)">{{ "timesheet.add_task" | translate }}</button>
                                        <button mat-button (click)="newRest($event, group.start, data.editableDays)">{{ "timesheet.add_rest" | translate }}</button>
                                    </mat-action-row>
                                }
                            </mat-expansion-panel>
                        }
                    </mat-accordion>

                    <div class="day-columns day-columns-footer">
                        <app-minutes-duration class="work-time" matTooltip="{{'timesheet.total_work_time' | translate}}" [minutes]="data.totals.workMinutes"/>
                        <app-minutes-duration class="boat-time" matTooltip="{{'timesheet.total_boat_time' | translate}}" [minutes]="data.totals.compensatedBoatMinutes"/>
                        @if (data.totals.compensatedKilometers !== 0 || data.totals.uncompensatedKilometers !== 0) {
                            <span class="kilometers">
                                <span matTooltip="4005, {{'timesheet.trip_pilotage' | translate}}">{{ data.totals.compensatedKilometers }}</span>
                                @if (data.totals.uncompensatedKilometers !== 0) {
                                    <small matTooltip="{{'timesheet.not_compensated_km' | translate}}">+{{ data.totals.uncompensatedKilometers }}</small>
                                }
                            </span>
                        }
                        <span class="allowance">{{ formatDailyAllowance(data.totals.dailyAllowanceFull, data.totals.dailyAllowancePart) }}</span>
                        <span class="trips">
                            @if (data.totals.shortTrips !== 0) {
                                <span matTooltip="{{'timesheet.over_6h_boat_trip' | translate}}">{{ data.totals.shortTrips }}<small>*6h</small></span>
                            }
                            @if (data.totals.longTrips !== 0) {
                                <span matTooltip="{{'timesheet.over_10h_boat_trip' | translate}}">{{ data.totals.longTrips }}<small>*10h</small></span>
                            }
                            @if (data.totals.shortTrips === 0 && data.totals.longTrips === 0) {
                                <span>-</span>
                            }
                        </span>
                        <app-minutes-duration class="training" [minutes]="data.totals.trainingMinutes" zeroValue="-"/>
                        <span class="overtime">
                            @if (data.totals.overtime50Minutes !== 0) {
                                <app-minutes-duration matTooltip="5875, {{'timesheet.overtime' | translate}} 50%" [minutes]="data.totals.overtime50Minutes" suffix="(50%)"/>
                            }
                            @if (data.totals.overtime75Minutes !== 0) {
                                <app-minutes-duration matTooltip="5878, {{'timesheet.overtime' | translate}} 75%" [minutes]="data.totals.overtime75Minutes" suffix="(75%)"/>
                            }
                            @if (data.totals.overtime50Minutes === 0 && data.totals.overtime75Minutes === 0) {
                                <span>-</span>
                            }
                        </span>
                    </div>
                </div>
            } @else {
                @if (data.displayMode === 'DAYS_WITH_COMPENSATION') {
                    {{ "timesheet.no_days_with_expenses" | translate }}
                }
                @if (data.displayMode === 'DAYS_WITH_ANY_ACTIVITY') {
                    {{ "timesheet.no_days_with_periods" | translate }}
                }
            }

        } @else {
            <div class="mat-error">{{ "timesheet.server_fetch_failed" | translate }}</div>
        }
    </mat-card>
} @else {
    <mat-spinner class="my-4 mx-auto"/>
}
