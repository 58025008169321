import { OperatorRestViolationReason, TimesheetState, VacationType } from "../operator-timesheet.service";
import { TranslateService } from "@ngx-translate/core";
import { commonTimesheetTranslation } from "./timesheet-common-translations";

export const timesheet_translations = {
    fi: {
        timesheet: {
            "header_day": "Päivä",
            "header_task": "Työ",
            "header_boat_time": "Veneelläajo",
            "header_allowance": "Pv-raha",
            "header_trips": "Matkat",
            "header_training": "Koulutus",
            "header_overtime": "Ylityö",
            "header_start": "Alku",
            "header_end": "Loppu",
            "header_duration": "Kesto",
            "header_notes": "Lisätiedot",

            "server_fetch_failed": "Kirjausten hakeminen palvelimelta epäonnistui.",
            "no_pilotage_station": "ei asemaa",
            "no_days_with_expenses": "Kuukaudelle ei ole työvuoroja joissa olisi korvauksia.",
            "no_days_with_periods": "Kuukaudelle ei ole työaikakirjauksia.",

            "add_task": "Lisää työtehtävä",
            "add_rest": "Lisää lepo",
            "new_rest": "Uusi lepo",
            "edit_rest": "Muokkaa lepoa",
            "period_start": "Alku",
            "period_end": "Loppu",
            "boat": "Vene",
            "trip_start": "Veneen lähtöaika",
            "trip_end": "Veneen saapumisaika",
            "end_before_start_error": "Loppu on ennen alkua",
            "edit_secondment": "Muokkaa komennusta",
            "trip_km": "Ajokilometrit",
            "notes": "Lisätiedot",
            "new_task": "Uusi tehtävä",
            "edit_task": "Muokkaa tehtävää",
            "task_type": "Tyyppi",
            "edit_rest_violation": "Rikkomuksen syy",
            "send_violation": "Lähetä vahvistettavaksi",
            "reason_for_violation": "Lepoaikarikkomuksen syy",
            "remove_period": "Poista kirjaus?",
            "acknowledge": "Vahvista",
            "violation_unfounded": "Aiheeton",
            "superior_marked_as_unfounded": "Esihenkilö on merkinnyt rikkomuksen aiheettomaksi.",
            "other_reason_needs_notes": "Muu syy vaatii selityksen.",
            "send_rest_violation_note": "Älä lähetä aiheetonta lepoaikarikkomusta vahvistettavaksi vaan poista rikkomus korjaamalla kirjauksia.",

            "over_6h_boat_trip": "yli 6h matka",
            "over_10h_boat_trip": "yli 10h matka",
            "total_work_time": "Työaika yhteensä",
            "total_boat_time": "Veneelläajokorvaukset yhteensä",
            "trip_pilotage": "Luotsin kuljetus",
            "not_compensated_km": "Ei-korvattavat kilometrit",
            "overtime": "ylityö",
            "days_with_expenses": "Päivät joilla korvauksia",
            "days_with_periods": "Päivät joilla on kirjauksia",
            "all_days": "Kaikki päivät",
            "overlap_warning": "Jakso on päällekkäinen toisen jakson kanssa.",
            "needs_overtime_note": "Ylityöltä puuttuu selite.",
            "missing_trip_period": "Puuttuva ajoaika.",
            "rest_violation_needs_resolving": "Lepoaikarikkomukselta puuttuu syy.",
            "has_work_on_vacation": "Lomalla töissä.",
            "periods_with_errors_note": "Päivän kirjauksilla on virheitä",
            "periods_with_notes_note": "Päivän kirjauksilla on lisätietoja",
            "no_periods_for_day": "Ei kirjauksia päivälle.",
            "no_periods_for_multiday": "Ei kirjauksia ajanjaksolle.",

            "secondment_note": "Komennus luotsiasemalla",
            "secondment_starts_note": "Komennus alkaa luotsiasemalla",
            "secondment_ends_note": "Komennus päättyy luotsiasemalla",

            "no_rest_period": "Lepo puuttuu lepoaikatarkastelujaksolta",

            "timesheet_title": "Työaikailmoitus",
            "message": "Viesti",
            "save": "Tallenna",
            "send": "Lähetä",
            "approve": "Hyväksy",
            "mark_as_completed": "Kuittaa käsitellyksi",
            "mark_as_completed_with_changes": "Kuittaa käsitellyksi muutoksin",
            "return_back": "Palauta",

            "vacations_title": "Lomat",
            "vacation_days": "Lomaoikeus",
            "vacation_types": {
                "NORMAL_VACATION": "Vuosilomalla",
                "SICK_LEAVE": "Sairaslomalla",
                "OTHER_PAID_LEAVE": "Muu palkallinen vapaa",
                "LEAVE_OF_ABSENCE": "Palkaton vapaa",
                "FURLOUGH": "Lomautus",
            } satisfies Record<VacationType, string>,
            "used_planned_vacation": "Pidetty / suunniteltu",
            "unplanned_vacation": "Suunniteltavissa",
            "saved_vacations_days": "Säästövapaat",
            "changed_holiday_bonus": "Lomarahan vaihtopäivät",

            approval_chain: { // TODO ruotsiksi
                approved: "Hyväksytty",
                non_approved: "Hyväksyntä puuttuu"
            },

            violation_reason: {
                "TRAFFIC": "Liikennetilanne",
                "WEATHER_CONDITIONS": "Sääolosuhteet",
                "EQUIPMENT": "Kalusto-ongelma",
                "PERSONNEL": "Henkilöstövaje",
                "ROTATION_SYSTEM": "Ajovuorottelujärjestelmä",
                "OTHER": "Muu syy"
            } satisfies Record<OperatorRestViolationReason, string>,

            timesheet_state: {
                "OPEN": "Avoin",
                "SUBMITTED": "Jätetty",
                "APPROVED": "Hyväksytty",
                "PROCESSED": "Käsitelty",
                "PROCESSED_WITH_CHANGES": "Käsitelty muutoksin",
                "RETURNED": "Palautettu",
                "TRANSFERRED": "Siirretty",
            } satisfies Record<TimesheetState, string>
        }
    },
    sv: {
        timesheet: {
            "header_day": "Dag",
            "header_task": "Arbete",
            "header_boat_time": "Veneelläajo", // TODO
            "header_allowance": "Dagpenning",
            "header_trips": "Resor",
            "header_training": "Skolning",
            "header_overtime": "Övertid",
            "header_start": "Start",
            "header_end": "Slut",
            "header_duration": "Varaktighet",
            "header_notes": "Tilläggsuppgifter",

            "server_fetch_failed": "Registreringarna kunde inte hittas på servern.",
            "no_pilotage_station": "ingen station",
            "no_days_with_expenses": "För denna månad finns inga arbetsskift med ersättningar ",
            "no_days_with_periods": "För denna månad finns inga arbetstidsregistreringar.",

            "add_task": "Lägg till ett arbetsuppdrag",
            "add_rest": "Lägg till vila",
            "new_rest": "Ny vila",
            "edit_rest": "Redigera vilan",
            "period_start": "Start",
            "period_end": "Slut",
            "boat": "Båt",
            "trip_start": "Båts avgångstid",
            "trip_end": "Båts ankomsttid",
            "end_before_start_error": "Slutet är före starten",
            "edit_secondment": "Redigera kommenderingen",
            "trip_km": "Körkilometrar",
            "notes": "Tilläggsuppgifter",
            "new_task": "Nytt uppdrag",
            "edit_task": "Redigera uppdraget",
            "task_type": "Typ",
            "edit_rest_violation": "Förseelsens orsak",
            "send_violation": "Sänd förklaringen",
            "reason_for_violation": "Vilotidsförseelsens orsak",
            "remove_period": "Ta bort registreringaren?",
            "acknowledge": "Kvittering",
            "violation_unfounded": "Obefogad",

            "over_6h_boat_trip": "över 6h resa",
            "over_10h_boat_trip": "över 10h resa",
            "total_work_time": "Arbetstid totalt",
            "total_boat_time": "Veneelläajokorvaukset yhteensä", // TODO
            "trip_pilotage": "Lotstransport",
            "not_compensated_km": "Icke ersättningsbara kilometrar",
            "overtime": "övertid",
            "days_with_expenses": "Dagar med ersättningar",
            "days_with_periods": "Dagar med registreringar",
            "all_days": "Alla dagar",
            "overlap_warning": "Perioden överlappar med en annan period.",
            "needs_overtime_note": "Förklaring för övertid saknas.",
            "missing_trip_period": "Körtid saknas.",
            "rest_violation_needs_resolving": "Vilotidsförseelsen kräver åtgärder.",
            "has_work_on_vacation": "Lomalla töissä.",
            "periods_with_errors_note": "Det finns fel i denna dags registreringar",
            "periods_with_notes_note": "Det finns tilläggsuppgifter med denna dags registreringar",
            "no_periods_for_day": "Inga registreringar för denna dag.",
            "no_periods_for_multiday": "Inga registreringar.", //TODO

            "secondment_note": "Kommendering på lotsstationen",
            "secondment_starts_note": "Kommenderingen börjar på lotsstationen",
            "secondment_ends_note": "Kommenderingen slutar på lotsstationen",

            "timesheet_title": "Arbetstidsanmälning",
            "message": "Meddelande",
            "save": "Spara",
            "send": "Skicka",
            "approve": "Godkänn",
            "mark_as_completed": "Kvittera som behandlat",
            "mark_as_completed_with_changes": "Kvittera som behandlat med ändringar",
            "return_back": "Returnera",

            "vacations_title": "Semestrar",
            "vacation_days": "Semesterrätt",
            "used_planned_vacation": "Använt / planerad",
            "unplanned_vacation": "Kan planeras",
            "saved_vacations_days": "Sparad semester",
            "changed_holiday_bonus": "Semesterpenningens bytesdagar",

            violation_reason: {
                "TRAFFIC": "Trafiksituation",
                "WEATHER_CONDITIONS": "Väderförhållanden",
                "EQUIPMENT": "Problem med båten",
                "PERSONNEL": "Personalbrist",
                "ROTATION_SYSTEM": "Körturssystemet",
                "OTHER": "Annan orsak"
            },

            timesheet_state: {
                "OPEN": "Öppen"
            }
        }
    }
};

export const SECONDMENT_TYPE_NAME_KEY = "timesheet.secondment";
export const SECONDMENT_NOTE_KEY = "timesheet.secondment_note";
export const SECONDMENT_STARTS_NOTE_KEY = "timesheet.secondment_starts_note";
export const SECONDMENT_ENDS_NOTE_KEY = "timesheet.secondment_ends_note";

export const REST_VIOLATION_TYPE_NAME_KEY = "timesheet.rest_violation";
export const MONITORING_PERIOD_WITHOUT_REST_LABEL = "timesheet.no_rest_period";

export function vacationTypeKey(type: VacationType): string {
    return `timesheet.vacation_types.${type}`;
}

export function restViolationReasonKey(reason: OperatorRestViolationReason): string {
    return `timesheet.violation_reason.${reason}`;
}

export function timesheetStateKey(state: TimesheetState): string {
    return `timesheet.timesheet_state.${state}`;
}

export function registerTimesheetTranslations(translateService: TranslateService): void {
    translateService.setTranslation("fi", commonTimesheetTranslation.fi, true);
    translateService.setTranslation("sv", commonTimesheetTranslation.sv, true);

    translateService.setTranslation("fi", timesheet_translations.fi, true);
    translateService.setTranslation("sv", timesheet_translations.sv, true);
}
