import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ChronoUnit, delayAtLeast, ErrorService, greaterThanInstant, Instant, InstantRange, LocalDate, LocalDateRange, localDateTimeToInstant, minMaxFromRange, MyMatDateTimePickerComponent, SpinnerButtonComponent, toLocalDateTime } from "common";
import { OperatorTimesheetModel } from "../operator-timesheet.model";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { OperatorTimesheetEndpoint } from "../../operator-timesheet.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    templateUrl: './operator-timesheet-edit-rest.component.html',
    styleUrls: ['./operator-timesheet-edit-rest.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MyMatDateTimePickerComponent,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TranslateModule,
    ]
})
export class OperatorTimesheetEditRestComponent {

    private readonly periodId: number | undefined;
    private readonly model: OperatorTimesheetModel;

    readonly selectableDays: { min: Instant | null, max: Instant | null };
    form?: FormGroup<EditRestForm>;
    saving = false;

    constructor(operatorTimesheetEndpoint: OperatorTimesheetEndpoint,
                private readonly changeDetectorRef: ChangeDetectorRef,
                private readonly dialogRef: MatDialogRef<OperatorTimesheetEditRestComponent>,
                private readonly errorService: ErrorService,
                @Inject(MAT_DIALOG_DATA) data: OperatorTimesheetEditRestComponentParams) {

        this.periodId = data.periodId;
        this.model = data.model;
        this.selectableDays = minMaxFromRange(data.selectableDays);

        if (this.periodId !== undefined) {
            operatorTimesheetEndpoint.findRestPeriodForEditing(this.periodId)
                .pipe(delayAtLeast(300))
                .subscribe({
                    next: range => {
                        this.form = createForm(range);

                        changeDetectorRef.markForCheck();
                    }, error: e => errorService.showLoadError(e)
                });
        } else {
            const now = toLocalDateTime(Instant.now().truncatedTo(ChronoUnit.MINUTES));
            const dateOfNewItem = data.dateOfNewItem!;
            const time = localDateTimeToInstant(dateOfNewItem.isEqual(now.toLocalDate()) ? now : dateOfNewItem.atStartOfDay());

            this.form = createForm(new InstantRange(time, time));
            changeDetectorRef.markForCheck();
        }
    }

    get titleKey(): string {
        return this.periodId === undefined ? "timesheet.new_rest" : "timesheet.edit_rest";
    }

    save(): void {
        this.saving = true;

        const form = this.form!;

        const range = new InstantRange(form.controls.startTime.value, form.controls.endTime.value);

        if (this.periodId !== undefined) {
            this.model.updateRestPeriod(this.periodId, range)
                .subscribe({
                    next: () => {
                        this.dialogRef.close(true);
                    }, error: e => {
                        this.saving = false;
                        this.changeDetectorRef.markForCheck();
                        this.errorService.showUpdateError(e);
                    }
                });
        } else {
            this.model.createRestPeriod(range)
                .subscribe({
                    next: () => {
                        this.dialogRef.close(true);
                    }, error: e => {
                        this.saving = false;
                        this.changeDetectorRef.markForCheck();
                        this.errorService.showUpdateError(e);
                    }
                });
        }
    }
}

export interface OperatorTimesheetEditRestComponentParams {
    model: OperatorTimesheetModel;
    periodId?: number;
    dateOfNewItem?: LocalDate;
    selectableDays: LocalDateRange | null;
}

interface EditRestForm {
    startTime: FormControl<Instant>;
    endTime: FormControl<Instant>;
}

function createForm(range: InstantRange): FormGroup<EditRestForm> {
    const form = new FormGroup({
        startTime: new FormControl(range.start, {validators: Validators.required, nonNullable: true}),
        endTime: new FormControl(range.end, {validators: Validators.required, nonNullable: true}),
    });

    form.controls.endTime.addValidators(greaterThanInstant(form.controls.startTime));
    form.controls.startTime.valueChanges.subscribe(() => {
        form.controls.endTime.updateValueAndValidity();
    });

    return form;
}
