export const commonTimesheetTranslation = {
    fi: {
        timesheet: {
            "boat_trip_pilotage": "Veneellä ajo",
            "boat_trip_other": "Veneellä ajo, muu",
            "car_trip_pilotage": "Autolla ajo, luotsaus",
            "car_trip_other": "Autolla ajo, muu",
            "maintenance_equipment": "Kalustonhuolto",
            "maintenance_buildings": "Kiinteistönhuolto",
            "other_work": "Toimisto- ja muu työ",
            "non_work_travel": "Muu matka",
            "station_meeting": "Asemakokous",
            "rest": "Lepo",
            "secondment": "Komennus",
            "rest_violation": "Lepoaikarikkomus",
            "resting": "Levossa",
            "automatic": "Automaattinen",
            "manual": "Manuaalinen"
        }
    },
    sv: {
        timesheet: {
            "boat_trip_pilotage": "Båtkörning, lotsning",
            "boat_trip_other": "Båtkörning, annan",
            "car_trip_pilotage": "Bilkörning, lotsning",
            "car_trip_other": "Bilkörning, annan",
            "maintenance_equipment": "Materielservice",
            "maintenance_buildings": "Fastighetservice",
            "other_work": "Kontors- eller annat arbete",
            "non_work_travel": "Övrig resa",
            "station_meeting": "Station möte",
            "rest": "Vila",
            "secondment": "Kommendering",
            "rest_violation": "Vilotidsförseelse",
            "resting": "I vila",
            "automatic": "Automatisk",
            "manual": "Manuell"
        }
    }
};
