import { Observable } from "rxjs";
import { Brand, Instant, InstantRange, LocalDate, LocalDateRange } from "common";
import { Provider, Type } from "@angular/core";

export abstract class OperatorTimesheetEndpoint {

    abstract createRestPeriod(operatorId: OperatorId, range: InstantRange): Observable<void>;
    abstract createWorkPeriod(operatorId: OperatorId, period: OperatorWorkPeriodDto): Observable<void>;
    abstract deleteRestPeriod(id: number): Observable<void>;
    abstract deleteWorkPeriod(id: number): Observable<void>;
    abstract findRestPeriodForEditing(id: number): Observable<InstantRange>;
    abstract findSecondmentForEditing(id: OperatorSecondmentId): Observable<SecondmentData>;
    abstract findTimesheetDays(operatorId: OperatorId, year: number | null, month: number | null): Observable<OperatorTimesheetData>;
    abstract findWorkPeriodForEditing(id: number): Observable<WorkPeriodEditData>;
    abstract updateRestPeriod(id: number, range: InstantRange): Observable<void>;
    abstract updateSecondment(id: OperatorSecondmentId, data: SecondmentData): Observable<void>;
    abstract updateWorkPeriod(id: number, period: OperatorWorkPeriodDto): Observable<void>;
    abstract updateRestViolation(id: number, data: OperatorRestViolationExplanationData): Observable<void>;
    abstract updateAndAcknowledgeRestViolation(id: number, data: OperatorRestViolationAcknowledgementData): Observable<void>;
    abstract findRestViolationForEditing(id: number): Observable<OperatorRestViolationDto>;
    abstract findOperatorTaskTypes(): Observable<OperatorTaskTypeInfo[]>;
    abstract findBoatData(operatorId: OperatorId): Observable<StationBoatsInfo[]>;
}

export function provideOperatorTimesheetEndpoint(endpointClass: Type<OperatorTimesheetEndpoint>): Provider {
    return {provide: OperatorTimesheetEndpoint, useClass: endpointClass};
}

export interface OperatorRestViolationAcknowledgementData {
    notes: string;
    selfInflicted: boolean;
}

export enum TimesheetState {
    OPEN = "OPEN",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
    PROCESSED = "PROCESSED",
    PROCESSED_WITH_CHANGES = "PROCESSED_WITH_CHANGES",
    RETURNED = "RETURNED",
    TRANSFERRED = "TRANSFERRED"
}

export interface OperatorRestViolationExplanationData {
    notes: string;
    reason: OperatorRestViolationReason | null;
}

export interface OperatorRestViolationDto {
    acknowledged: Instant | null;
    cleared: boolean;
    extendedRestViolation: boolean;
    mayAcknowledge: boolean;
    notes: string;
    notifySent: Instant | null;
    reason: OperatorRestViolationReason | null;
    selfInflicted: boolean;
    violationTime: Instant;
}

export interface OperatorTaskTypeInfo {
    hasKilometers: boolean;
    hasTripDuration: boolean;
    hasBoatSelection: boolean;
    name: string;
    type: OperatorTaskType;
}

export interface StationBoatsInfo {
    stationName: string;
    boats: PilotBoatInfo[];
}

export interface PilotBoatInfo {
    id: number;
    name: string;
    nickName: string | null;
}

export interface OperatorWorkPeriodDto {
    kilometers: number | null;
    notes: string;
    range: InstantRange;
    manualTripRange: InstantRange | null;
    type: OperatorTaskType;
    boatId: number | null;
}

export enum OperatorTaskType {
    BOAT_TRIP_PILOTAGE = "BOAT_TRIP_PILOTAGE",
    BOAT_TRIP_OTHER = "BOAT_TRIP_OTHER",
    CAR_TRIP_PILOTAGE = "CAR_TRIP_PILOTAGE",
    CAR_TRIP_OTHER = "CAR_TRIP_OTHER",
    MAINTENANCE_EQUIPMENT = "MAINTENANCE_EQUIPMENT",
    MAINTENANCE_BUILDINGS = "MAINTENANCE_BUILDINGS",
    NON_WORK_TRAVEL = "NON_WORK_TRAVEL",
    OTHER_WORK = "OTHER_WORK",
    STATION_MEETING = "STATION_MEETING"
}

export interface OperatorRestPeriodDto {
    id: number;
    range: InstantRange;
}

export interface OperatorWorkPeriodInfo {
    id: number;
    kilometers: number | null;
    notes: string;
    range: InstantRange;
    type: OperatorTaskType;
}

export interface SecondmentData {
    end: Instant | null;
    start: Instant | null;
}

export interface OperatorTimesheetData {
    days: OperatorTimesheetPeriodGroup[];
    totals: OperatorTimesheetTotals;
    valid: boolean;
    editableDays: LocalDateRange | null;
    absences: OperatorTimesheetAbsenceInfo[];
    secondments: OperatorTimesheetSecondmentInfo[];
    timestamp: Instant;
}

export interface OperatorTimesheetAbsenceInfo {
    days: number;
    description: string;
    end: LocalDate;
    start: LocalDate;
}

export interface WorkPeriodEditData {
    period: OperatorWorkPeriodDto;
    automaticTripRange: InstantRange | null;
}

export interface OperatorTimesheetPeriodGroup {
    compensatedKilometers: number;
    dailyAllowanceFull: number;
    dailyAllowancePart: number;
    start: LocalDate;
    end: LocalDate;
    hasCompensations: boolean;
    hasErrors: boolean;
    hasValidityErrors: boolean;
    longTrips: number;
    overtime50Minutes: number;
    overtime75Minutes: number;
    restPeriods: OperatorTimesheetRestPeriod[];
    secondmentPeriods: OperatorSecondmentPeriodDto[];
    restViolations: OperatorTimesheetRestViolation[];
    noRestPeriods: RestMonitoringPeriodWithoutRest[];
    shortTrips: number;
    trainingMinutes: number;
    tyovuoroMerkki: string;
    tyovuoroMerkkiKuvaus: string;
    uncompensatedKilometers: number;
    vacationType: VacationType | null;
    plannedShift: boolean;
    workMinutes: number;
    workMinutesWithinPeriodGroup: number;
    compensatedBoatMinutesWithinPeriodGroup: number;
    workPeriods: OperatorTimesheetWorkPeriod[];
    editableRangeInGroup: LocalDateRange | null;
}

export interface OperatorTimesheetWorkPeriod {
    errors: TimesheetPeriodErrors;
    id: number;
    kilometers: number | null;
    notes: string;
    range: InstantRange;
    tripRange: InstantRange | null;
    tripStartImplicit: boolean;
    hasManualTripRange: boolean;
    type: OperatorTaskType;
    boatId: number | null;
    vesselMeetups: VesselMeetupInfo[];
}

export interface VesselMeetupInfo {
    time: Instant;
    vesselMmsi: number;
    vesselName: string | null;
}

export interface OperatorTimesheetRestPeriod {
    errors: TimesheetPeriodErrors;
    id: number;
    partialRest: boolean;
    range: InstantRange;
}

export interface OperatorSecondmentPeriodDto {
    endDate: LocalDate;
    endTime: Instant | null;
    estimatedRange: InstantRange;
    id: OperatorSecondmentId;
    startDate: LocalDate;
    startTime: Instant | null;
    stationId: PilotStationId;
    stationName: string;
}

export interface OperatorTimesheetRestViolation {
    id: number;
    notes: string;
    notifySent: Instant | null;
    reason: OperatorRestViolationReason | null;
    violationTime: Instant;
    errors: TimesheetPeriodErrors;
    acknowledgementNeeded: boolean;
}

export interface  RestMonitoringPeriodWithoutRest {
    range: InstantRange;
}

export enum OperatorRestViolationReason {
    WEATHER_CONDITIONS = "WEATHER_CONDITIONS",
    TRAFFIC = "TRAFFIC",
    EQUIPMENT = "EQUIPMENT",
    PERSONNEL = "PERSONNEL",
    ROTATION_SYSTEM = "ROTATION_SYSTEM",
    OTHER = "OTHER"
}

export interface OperatorTimesheetTotals {
    compensatedKilometers: number;
    dailyAllowanceFull: number;
    dailyAllowancePart: number;
    longTrips: number;
    overtime50Minutes: number;
    overtime75Minutes: number;
    shortTrips: number;
    trainingMinutes: number;
    uncompensatedKilometers: number;
    workMinutes: number;
    compensatedBoatMinutes: number;
}

export interface TimesheetPeriodErrors {
    overlapping: boolean;
    missingTripPeriod: boolean;
    needsOvertimeNote: boolean;
    needsRestViolationReason: boolean;
    hasWorkOnVacation: boolean;
    noRestInMonitoringPeriod: boolean;
}


export interface OperatorVacationPeriod {
    start: LocalDate;
    end: LocalDate;
    vacationType: VacationType;
}

export enum VacationType {
    NORMAL_VACATION = "NORMAL_VACATION",
    SICK_LEAVE = "SICK_LEAVE",
    OTHER_PAID_LEAVE = "OTHER_PAID_LEAVE",
    LEAVE_OF_ABSENCE = "LEAVE_OF_ABSENCE",
    FURLOUGH = "FURLOUGH",
}

export interface OperatorTimesheetSecondmentInfo {
    range: InstantRange;
    stationName: string;
}

export interface OperatorTimesheetAbsence {
    start: LocalDate;
    end: LocalDate;
    description: string;
    days: number;
}

export type OperatorSecondmentId = Brand<number, "OperatorSecondmentId">;
export type OperatorId = Brand<number, "OperatorId">;
export type PilotStationId = Brand<number, "PilotStationId">;
