import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { PeriodInfo } from "../operator-timesheet.model";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    templateUrl: './operator-timesheet-notes-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
    ]
})
export class OperatorTimesheetNotesDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public readonly data: PeriodInfo) {
    }
}
