import { ChangeDetectionStrategy, Component, computed, input, Signal } from "@angular/core";
import { throwError } from "rxjs";
import { OperatorTimesheetModel, PeriodGroupInfo, PeriodInfo, PeriodType } from "./operator-timesheet.model";
import { ActivityMinimapComponent, brand, comparators, comparing, DialogsService, ErrorService, HelsinkiDatePipe, Instant, InstantRange, LocalDate, LocalDateRange, localDateTimeToInstant, MinimapPeriod, MinutesDurationComponent, reverseOrder, sorted, SpinnerButtonComponent, YearMonth } from "common";
import { OperatorId, OperatorTaskType, OperatorTimesheetAbsence, OperatorTimesheetEndpoint, OperatorTimesheetSecondmentInfo, OperatorTimesheetTotals } from "../operator-timesheet.service";
import { MatDialog, MatDialogConfig, MatDialogModule } from "@angular/material/dialog";
import { OperatorTimesheetEditRestComponent, OperatorTimesheetEditRestComponentParams } from "./operator-timesheet-edit-rest/operator-timesheet-edit-rest.component";
import { OperatorTimesheetEditTaskComponent, OperatorTimesheetEditTaskComponentParams } from "./operator-timesheet-edit-task/operator-timesheet-edit-task.component";
import { OperatorTimesheetEditSecondmentComponent, OperatorTimesheetEditSecondmentComponentParams } from "./operator-timesheet-edit-secondment/operator-timesheet-edit-secondment.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ComponentType } from "@angular/cdk/portal";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { OperatorTimesheetEditViolationComponent, OperatorTimesheetEditViolationComponentParams } from "./operator-timesheet-edit-violation/operator-timesheet-edit-violation.component";
import { OperatorTimesheetNotesDialogComponent } from "./operator-timesheet-notes-dialog/operator-timesheet-notes-dialog.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ThemePalette } from "@angular/material/core";
import { OperatorTimesheetReferenceDataService } from "../operator-timesheet-reference-data.service";
import { first } from "rxjs/operators";
import { OperatorTimesheetTimeComponent } from "./operator-timesheet-time/operator-timesheet-time.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";

const LOAD_FAILED = 'load-failed';

@Component({
    selector: 'app-operator-timesheet-tasks',
    templateUrl: './operator-timesheet-tasks.component.html',
    styleUrls: ['./operator-timesheet-tasks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SpinnerButtonComponent,
        HelsinkiDatePipe,
        ActivityMinimapComponent,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatTooltipModule,
        MinutesDurationComponent,
        OperatorTimesheetEditRestComponent,
        OperatorTimesheetEditSecondmentComponent,
        OperatorTimesheetEditTaskComponent,
        OperatorTimesheetEditViolationComponent,
        OperatorTimesheetNotesDialogComponent,
        OperatorTimesheetTimeComponent,
        ReactiveFormsModule,
        TranslateModule,
    ]
})
export class OperatorTimesheetTasksComponent {

    readonly data: Signal<typeof LOAD_FAILED | TimesheetViewData | null>;
    readonly locale: string;

    operatorId = input.required<OperatorId>();
    range = input<YearMonth | "recent">("recent");
    displayMode = input<TimesheetDisplayMode>(TimesheetDisplayMode.DAYS_WITH_COMPENSATION);
    timeOrdering = input<TimesheetTimeOrdering>(TimesheetTimeOrdering.OLDEST_FIRST);

    readonly valid: Signal<TimesheetValidityInfo | null>;
    private mobileLayoutActivated = false;

    private readonly model: Signal<OperatorTimesheetModel | null>;

    constructor(
        private readonly matDialog: MatDialog,
        readonly operatorTimesheetEndpoint: OperatorTimesheetEndpoint,
        private readonly operatorTimesheetReferenceDataService: OperatorTimesheetReferenceDataService,
        private readonly dialogsService: DialogsService,
        private readonly translateService: TranslateService,
        breakpointObserver: BreakpointObserver,
        readonly errorService: ErrorService,
    ) {

        breakpointObserver.observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait]).pipe(takeUntilDestroyed()).subscribe(result => {
            this.mobileLayoutActivated = result.matches;
        });

        this.model = computed(() => {
            const operatorId = this.operatorId();
            return new OperatorTimesheetModel(operatorTimesheetEndpoint, translateService, operatorId, this.range());
        });

        this.data = computed(() => {
            const sorting = this.timeOrdering();
            const displayMode = this.displayMode();
            const data = this.model()?.data();
            if (data == null)
                return null;
            if (data === "load-failed")
                return LOAD_FAILED;

            const allPeriods: PeriodInfo[] = [];
            const minimapPeriods: MinimapPeriod[] = [];
            for (const d of data.groups) {
                allPeriods.push(...d.periods);

                for (const p of d.periods) {
                    minimapPeriods.push({
                        range: p.range,
                        label: p.label,
                        cssClass: minimapCssClassFor(p)
                    });

                    if (p.tripRange != null) {
                        minimapPeriods.push({
                            range: p.tripRange,
                            label: null,
                            cssClass: p.hasManualTripRange ? 'real-boat-trip-period manual' : 'real-boat-trip-period'
                        });
                    }

                    for (const meetup of p.vesselMeetups ?? []) {
                        minimapPeriods.push({
                            range: new InstantRange(meetup.time, meetup.time),
                            label: `${meetup.vesselMmsi}: ${meetup.vesselName ?? '-'}`,
                            cssClass: "vessel-meetup"
                        });
                    }
                }
            }

            const filteredDays = data.groups.filter(d => filterDay(d, displayMode, allPeriods));
            const sortedGroups = sortPeriodGroups(filteredDays, sorting);

            return {
                groups: sortedGroups,
                allPeriods: allPeriods, // non-sorted
                minimapPeriods: minimapPeriods,
                displayMode: displayMode,
                totals: data.totals,
                valid: data.valid,
                editableDays: data.editableDays,
                printData: {
                    groups: sortPeriodGroups(data.groups.filter(d => d.hasCompensations), TimesheetTimeOrdering.OLDEST_FIRST),
                    secondments: data.secondments,
                    absences: data.absences
                },
                timestamp: data.timestamp
            } satisfies TimesheetViewData;
        });

        this.valid = computed(() => {
            const data = this.data();
            if (data == null || data === LOAD_FAILED)
                return null;
            else
                return {
                    isValid: data.valid,
                    timestamp: data.timestamp
                } satisfies TimesheetValidityInfo;
        });

        const lang = translateService.currentLang;
        if (["sv", "fi"].includes(lang))
            this.locale = `${lang}-FI`;
        else
            this.locale = "fi-FI";
    }

    open(row: PeriodInfo, selectableDays: LocalDateRange | null): void {
        const model = this.model()!;

        if (row.type === PeriodType.REST) {
            this.openDialog<OperatorTimesheetEditRestComponent, OperatorTimesheetEditRestComponentParams>(OperatorTimesheetEditRestComponent, {
                model: model,
                periodId: row.id,
                selectableDays: selectableDays
            });
        } else if (row.type === PeriodType.WORK) {
            this.operatorTimesheetReferenceDataService.operatorTaskTypes$.pipe(first()).subscribe({
                next: operatorTaskTypes => {
                    this.openDialog<OperatorTimesheetEditTaskComponent, OperatorTimesheetEditTaskComponentParams>(OperatorTimesheetEditTaskComponent, {
                        model: model,
                        periodId: row.id,
                        selectableDays: selectableDays,
                        operatorTaskTypes
                    });
                },
                error: e => this.errorService.showLoadError(e)
            });
        } else if (row.type === PeriodType.SECONDMENT) {
            this.openDialog<OperatorTimesheetEditSecondmentComponent, OperatorTimesheetEditSecondmentComponentParams>(OperatorTimesheetEditSecondmentComponent, {
                model: model,
                secondmentId: brand(row.id),
                selectableDays: selectableDays
            });
        } else if (row.type === PeriodType.REST_VIOLATION) {
            this.openDialog<OperatorTimesheetEditViolationComponent, OperatorTimesheetEditViolationComponentParams>(OperatorTimesheetEditViolationComponent, {
                model: model,
                violationId: row.id
            });
        }
    }

    showNotes(row: PeriodInfo): void {
        this.matDialog.open(OperatorTimesheetNotesDialogComponent, {data: row});
    }

    hasErrors(row: PeriodInfo): boolean {
        const errors = row.errors;
        if (errors == null)
            return false;

        // errors.noRestInMonitoringPeriod is purposefully ignored here, it's reported in another way
        return errors.overlapping || errors.missingTripPeriod || errors.needsOvertimeNote ||
            errors.needsRestViolationReason || errors.hasWorkOnVacation;
    }

    remove(row: PeriodInfo): void {
        const model = this.model()!;

        this.dialogsService.showConfirmationDialog({
            question: this.translateService.instant("timesheet.remove_period"),
            confirmText: this.translateService.instant("common.remove")
        }, () => {
            switch (row.type) {
                case PeriodType.REST:
                    return model.deleteRestPeriod(row.id);
                case PeriodType.WORK:
                    return model.deleteWorkPeriod(row.id);
                case PeriodType.SECONDMENT:
                    return throwError(() => "deleting secondments is not supported");
                case PeriodType.REST_VIOLATION:
                    return throwError(() => "deleting rest violations is not supported");
                default:
                    return throwError(() => `unknown row type: ${row.type}`);
            }
        });
    }

    newTask(event: Event, date: LocalDate, selectableDays: LocalDateRange | null): void {
        event.preventDefault();
        event.stopPropagation();

        const model = this.model()!;

        this.operatorTimesheetReferenceDataService.operatorTaskTypes$.pipe(first()).subscribe({
            next: operatorTaskTypes => {
                this.openDialog<OperatorTimesheetEditTaskComponent, OperatorTimesheetEditTaskComponentParams>(OperatorTimesheetEditTaskComponent, {
                    model: model,
                    dateOfNewItem: date,
                    selectableDays: selectableDays,
                    operatorTaskTypes
                });
            },
            error: e => this.errorService.showLoadError(e)
        });
    }

    newRest(event: Event, date: LocalDate, selectableDays: LocalDateRange | null): void {
        event.preventDefault();
        event.stopPropagation();

        const model = this.model()!;

        this.openDialog<OperatorTimesheetEditRestComponent, OperatorTimesheetEditRestComponentParams>(OperatorTimesheetEditRestComponent, {
            model: model,
            dateOfNewItem: date,
            selectableDays: selectableDays
        });
    }

    formatTrips(group: PeriodGroupInfo): string {
        if (group.shortTrips === 0 && group.longTrips === 0) return "";

        const trips: string[] = [];

        for (let i = 0; i < group.shortTrips; i++)
            trips.push("6h");

        for (let i = 0; i < group.longTrips; i++)
            trips.push("10h");

        return trips.join(", ");
    }

    formatDailyAllowance(full: number, part: number): string {
        const parts: string[] = [];

        if (full > 0)
            parts.push(`${full}`);

        if (part > 1)
            parts.push(`${part}*½`);
        else if (part === 1)
            parts.push(`½`);

        if (parts.length > 1)
            return parts.join(" + ");
        else if (parts.length === 1)
            return parts[0];
        else
            return "";
    }

    private openDialog<T, D>(componentOrTemplateRef: ComponentType<T>, data: D, params: MatDialogConfig = {}): void {
        const dialogViewSizes = this.mobileLayoutActivated ? {width: '100vw', height: '100vh', maxWidth: '500px'} : {minWidth: '500px'};
        this.matDialog.open<T, D>(componentOrTemplateRef, {data: data, ...dialogViewSizes, ...params});
    }

    iconColor(important: boolean): ThemePalette {
        return important ? "warn" : undefined;
    }

    refresh(): void {
        this.model()?.refresh().subscribe();
    }

    rangeMinutes(range: InstantRange): number {
        return range.actualDuration().toMinutes();
    }
}

const ascendingGroupComparator = comparing<PeriodGroupInfo>(group => group.start.toString());
const ascendingPeriodComparator = comparators(
    comparing<PeriodInfo>(r => r.range.start.toEpochMilli()),
    comparing<PeriodInfo>(r => r.range.end.toEpochMilli()));

function sortPeriodGroups(groupInfos: PeriodGroupInfo[], order: TimesheetTimeOrdering): PeriodGroupInfo[] {
    const asc = order === TimesheetTimeOrdering.OLDEST_FIRST;
    const groupComparator = asc ? ascendingGroupComparator : reverseOrder(ascendingGroupComparator);
    const periodComparator = asc ? ascendingPeriodComparator : reverseOrder(ascendingPeriodComparator);
    return sorted(groupInfos, groupComparator)
        .map(group => ({...group, periods: sorted(group.periods, periodComparator)}));
}

function filterDay(period: PeriodGroupInfo, displayMode: TimesheetDisplayMode, allPeriods: PeriodInfo[]): boolean {
    switch (displayMode) {
        case TimesheetDisplayMode.DAYS_WITH_COMPENSATION:
            return period.hasCompensations || period.hasErrors || period.hasNotes || period.isVacation;
        case TimesheetDisplayMode.DAYS_WITH_ANY_ACTIVITY:
            return period.isPlannedShift || allPeriods.find(p => p.range.overlaps(fullDateRange(period.start, period.end))) !== undefined;
        case TimesheetDisplayMode.ALL_DAYS:
            return true;
    }
}

function fullDateRange(start: LocalDate, end: LocalDate): InstantRange {
    const s = start.atStartOfDay();
    const e = end.plusDays(1).atStartOfDay();
    return new InstantRange(localDateTimeToInstant(s), localDateTimeToInstant(e));
}

export enum TimesheetDisplayMode {
    DAYS_WITH_COMPENSATION = "DAYS_WITH_COMPENSATION",
    DAYS_WITH_ANY_ACTIVITY = "DAYS_WITH_ANY_ACTIVITY",
    ALL_DAYS = "ALL_DAYS"
}

export enum TimesheetTimeOrdering {
    RECENT_FIRST = "RECENT_FIRST",
    OLDEST_FIRST = "OLDEST_FIRST"
}

export interface TimesheetValidityInfo {
    isValid: boolean;
    timestamp: Instant;
}

interface TimesheetViewData {
    groups: PeriodGroupInfo[];
    allPeriods: PeriodInfo[];
    minimapPeriods: MinimapPeriod[];
    totals: OperatorTimesheetTotals;
    editableDays: LocalDateRange | null;
    printData: {
        groups: PeriodGroupInfo[],
        secondments: OperatorTimesheetSecondmentInfo[];
        absences: OperatorTimesheetAbsence[];
    };
    displayMode: TimesheetDisplayMode;
    valid: boolean;
    timestamp: Instant;
}

function minimapCssClassFor(period: PeriodInfo): string {
    if (period.type === PeriodType.REST && period.partialRest)
        return "bg-work-period-rest-partial";

    if (period.taskType !== undefined)
        return stylesByTaskType[period.taskType];
    else
        return stylesByPeriodType[period.type];
}

const stylesByTaskType: Record<OperatorTaskType, string> = {
    BOAT_TRIP_PILOTAGE: "bg-work-period-boat-trip",
    BOAT_TRIP_OTHER: "bg-work-period-boat-trip",
    CAR_TRIP_PILOTAGE: "bg-work-period-car-trip",
    CAR_TRIP_OTHER: "bg-work-period-car-trip",
    MAINTENANCE_EQUIPMENT: "bg-work-period-maintenance-equipment",
    MAINTENANCE_BUILDINGS: "bg-work-period-maintenance-buildings",
    OTHER_WORK: "bg-work-period-other-work",
    NON_WORK_TRAVEL: "bg-work-period-non-work-travel",
    STATION_MEETING: "bg-work-period-station-meeting",
};

const stylesByPeriodType: Record<PeriodType, string> = {
    work: "bg-work-period-work",
    rest: "bg-work-period-rest",
    secondment: "bg-work-period-secondment secondment",
    rest_violation: "rest_violation",
    no_rest: "no_rest",
};
