@if (form) {
    <h2 mat-dialog-title>{{ "timesheet.edit_secondment" | translate }}</h2>
    <mat-dialog-content>

        <div class="form-container">
            <div class="times">
                <mat-form-field>
                    <mat-label>{{ 'timesheet.period_start' | translate }}</mat-label>
                    <app-mat-date-time-picker [min]="selectableDays.min" [max]="selectableDays.max" [formControl]="form.controls.start"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'timesheet.period_end' | translate }}</mat-label>
                    <app-mat-date-time-picker [min]="selectableDays.min" [max]="selectableDays.max" [formControl]="form.controls.end"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                    <mat-error>{{ 'timesheet.end_before_start_error' | translate }}</mat-error>
                </mat-form-field>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ "common.cancel" | translate }}</button>
        <app-spinner-button (spinnerClick)="save()" [active]="saving" [disabled]="form.invalid">{{ "common.save" | translate }}</app-spinner-button>
    </mat-dialog-actions>
} @else {
    <mat-spinner/>
}
