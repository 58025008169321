import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { OperatorTaskTypeInfo, OperatorTimesheetEndpoint } from "./operator-timesheet.service";
import { shareReplay } from "rxjs/operators";

@Injectable({providedIn: "root"})
export class OperatorTimesheetReferenceDataService {

    public readonly operatorTaskTypes$: Observable<OperatorTaskTypeInfo[]>;

    constructor(operatorTimesheetEndpoint: OperatorTimesheetEndpoint) {
        this.operatorTaskTypes$ = operatorTimesheetEndpoint.findOperatorTaskTypes()
            .pipe(shareReplay(1));
    }
}
