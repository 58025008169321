import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { delayAtLeast, ErrorService, greaterThanInstant, Instant, LocalDateRange, minMaxFromRange, MyMatDateTimePickerComponent, SpinnerButtonComponent } from "common";
import { OperatorTimesheetModel } from "../operator-timesheet.model";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { OperatorSecondmentId, OperatorTimesheetEndpoint, SecondmentData } from "../../operator-timesheet.service";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    templateUrl: './operator-timesheet-edit-secondment.component.html',
    styleUrls: ['./operator-timesheet-edit-secondment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MyMatDateTimePickerComponent,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TranslateModule,
    ]
})
export class OperatorTimesheetEditSecondmentComponent {

    private readonly secondmentId: OperatorSecondmentId;
    private readonly model: OperatorTimesheetModel;

    form?: FormGroup<EditSecondmentForm>;
    saving = false;

    readonly selectableDays: { min: Instant | null, max: Instant | null };

    constructor(operatorTimesheetEndpoint: OperatorTimesheetEndpoint,
                private readonly changeDetectorRef: ChangeDetectorRef,
                private readonly dialogRef: MatDialogRef<OperatorTimesheetEditSecondmentComponent>,
                private readonly errorService: ErrorService,
                @Inject(MAT_DIALOG_DATA) data: OperatorTimesheetEditSecondmentComponentParams) {

        this.secondmentId = data.secondmentId;
        this.model = data.model;
        this.selectableDays = minMaxFromRange(data.selectableDays);

        operatorTimesheetEndpoint.findSecondmentForEditing(this.secondmentId)
            .pipe(delayAtLeast(300))
            .subscribe({
                next: d => {
                    this.form = createForm(d);

                    changeDetectorRef.markForCheck();
                },
                error: e => errorService.showLoadError(e)
            });
    }

    save(): void {
        this.saving = true;

        const form = this.form!.value;
        const data = {start: form.start!, end: form.end!};

        this.model.updateSecondment(this.secondmentId, data).subscribe({
            next: () => this.dialogRef.close(true),
            error: e => {
                this.saving = false;
                this.changeDetectorRef.markForCheck();
                this.errorService.showUpdateError(e);
            }
        });
    }
}

export interface OperatorTimesheetEditSecondmentComponentParams {
    secondmentId: OperatorSecondmentId;
    model: OperatorTimesheetModel;
    selectableDays: LocalDateRange | null;
}

interface EditSecondmentForm {
    start: FormControl<Instant | null>;
    end: FormControl<Instant | null>;
}

function createForm(data: SecondmentData): FormGroup<EditSecondmentForm> {
    const form = new FormGroup<EditSecondmentForm>({
        start: new FormControl(data.start),
        end: new FormControl(data.end),
    });

    form.controls.end.addValidators(greaterThanInstant(form.controls.start));
    form.controls.start.valueChanges.subscribe(() => {
        form.controls.end.updateValueAndValidity();
    });

    return form;
}
