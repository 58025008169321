import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HelsinkiDatePipe, LocalDate, WeekdayNamePipe } from "common";

@Component({
    selector: 'app-operator-timesheet-time',
    templateUrl: './operator-timesheet-time.component.html',
    styleUrls: ['./operator-timesheet-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HelsinkiDatePipe,
        WeekdayNamePipe,
    ]
})
export class OperatorTimesheetTimeComponent {

    @Input() day!: LocalDate;
    @Input() locale!: string;
}
