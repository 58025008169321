import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, Inject, Injector, signal, Signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { controlValuesSignal, delayAtLeast, enableWhen, ErrorService, greaterThanInstant, Instant, InstantRange, lessOrEqualThanInstant, lessThanInstant, LocalDate, LocalDateRange, localDateTimeToInstant, minMaxFromRange, MyMatDateTimePickerComponent, SpinnerButtonComponent, toLocalDateTime, validateWhen } from "common";
import { OperatorTimesheetModel } from "../operator-timesheet.model";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { OperatorTaskType, OperatorTaskTypeInfo, OperatorTimesheetEndpoint, OperatorWorkPeriodDto, StationBoatsInfo, WorkPeriodEditData } from "../../operator-timesheet.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { getTaskDescriptionKey } from "../../operator-task-utils";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { toSignal } from "@angular/core/rxjs-interop";

type TripTimeType = "automatic" | "manual";

@Component({
    templateUrl: './operator-timesheet-edit-task.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MyMatDateTimePickerComponent,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TranslateModule,
    ],
})
export class OperatorTimesheetEditTaskComponent {

    private readonly periodId: number | undefined;
    private readonly model: OperatorTimesheetModel;

    readonly selectableDays: { min: Instant | null, max: Instant | null };
    readonly taskTypes: OperatorTaskTypeInfo[];

    form!: FormGroup<TripFormControls>;

    readonly ready = signal(false);
    readonly tripTimeType = new FormControl<TripTimeType>("automatic", {nonNullable: true});
    readonly saving = signal(false);
    private readonly hasAutomaticTripRange = signal(false);

    readonly boatsInfo: Signal<StationBoatsInfo[]>;

    constructor(
        operatorTimesheetEndpoint: OperatorTimesheetEndpoint,
        changeDetectorRef: ChangeDetectorRef,
        private readonly dialogRef: MatDialogRef<OperatorTimesheetEditTaskComponent>,
        private readonly errorService: ErrorService,
        translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) data: OperatorTimesheetEditTaskComponentParams,
        injector: Injector,
    ) {
        this.periodId = data.periodId;
        this.model = data.model;
        this.selectableDays = minMaxFromRange(data.selectableDays);

        this.boatsInfo = toSignal(operatorTimesheetEndpoint.findBoatData(this.model.operatorId), {initialValue: []});
        const tripTimeTypeSignal = controlValuesSignal(this.tripTimeType);

        this.taskTypes = data.operatorTaskTypes.map(it => ({...it, name: translateService.instant(getTaskDescriptionKey(it.type))}));

        if (this.periodId !== undefined) {
            operatorTimesheetEndpoint.findWorkPeriodForEditing(this.periodId)
                .pipe(delayAtLeast(300))
                .subscribe({
                    next: editData => {
                        this.tripTimeType.reset(editData.period.manualTripRange != null ? "manual" : "automatic");
                        this.hasAutomaticTripRange.set(editData.automaticTripRange != null);

                        this.form = createForm(editData, this.taskTypes, tripTimeTypeSignal, injector);

                        this.ready.set(true);
                        changeDetectorRef.markForCheck();
                    },
                    error: e => errorService.showLoadError(e)
                });
        } else {
            const now = toLocalDateTime(Instant.now());
            const dateOfNewItem = data.dateOfNewItem!;
            const time = localDateTimeToInstant(dateOfNewItem.isEqual(now.toLocalDate()) ? now : dateOfNewItem.atStartOfDay());

            this.form = createForm({
                period: {
                    range: new InstantRange(time, time),
                    manualTripRange: null,
                    type: OperatorTaskType.BOAT_TRIP_PILOTAGE,
                    notes: '',
                    kilometers: null,
                    boatId: null
                },
                automaticTripRange: null
            }, this.taskTypes, tripTimeTypeSignal, injector);
            this.ready.set(true);
        }
    }

    private get formValue(): TripForm {
        return this.form.value as TripForm;
    }

    get titleKey(): string {
        return this.periodId === undefined ? "timesheet.new_task" : "timesheet.edit_task";
    }

    get tripStartInitialPickerValue(): Instant | null {
        const data = this.formValue;
        return data.tripStart ?? data.startDate;
    }

    get tripEndInitialPickerValue(): Instant | null {
        const data = this.formValue;
        return data.tripEnd ?? data.endDate;
    }

    get showKilometers(): boolean {
        return this.formValue.type.hasKilometers;
    }

    get showBoatSelection(): boolean {
        return this.formValue.type.hasBoatSelection;
    }

    get showTripRange(): boolean {
        return this.formValue.type.hasTripDuration && (this.hasAutomaticTripRange() || this.tripTimeType.value === 'manual');
    }

    save(): void {
        this.saving.set(true);

        const data = this.formValue;

        const period: OperatorWorkPeriodDto = {
            range: new InstantRange(data.startDate, data.endDate),
            type: data.type.type,
            manualTripRange: (data.tripStart || data.tripEnd) ? new InstantRange(data.tripStart ?? data.startDate, data.tripEnd ?? data.endDate) : null,
            kilometers: data.type.hasKilometers ? data.kilometers : null,
            notes: data.notes,
            boatId: data.boatId
        };

        if (this.periodId !== undefined) {
            this.model.updateWorkPeriod(this.periodId, period).subscribe({
                next: () => {
                    this.dialogRef.close(true);
                },
                error: e => {
                    this.saving.set(false);
                    this.errorService.showUpdateError(e);
                }
            });
        } else {
            this.model.createWorkPeriod(period).subscribe({
                next: () => {
                    this.dialogRef.close(true);
                },
                error: e => {
                    this.saving.set(false);
                    this.errorService.showUpdateError(e);
                }
            });
        }
    }
}

export interface OperatorTimesheetEditTaskComponentParams {
    model: OperatorTimesheetModel;
    periodId?: number;
    dateOfNewItem?: LocalDate;
    selectableDays: LocalDateRange | null;
    operatorTaskTypes: OperatorTaskTypeInfo[];
}

interface TripForm {
    type: OperatorTaskTypeInfo;
    startDate: Instant;
    endDate: Instant;
    tripStart: Instant | null;
    tripEnd: Instant | null;
    kilometers: number | null;
    notes: string;
    boatId: number | null;
}

function createForm(data: WorkPeriodEditData, taskTypes: OperatorTaskTypeInfo[], tripTimeType: Signal<TripTimeType>, injector: Injector): FormGroup<TripFormControls> {
    const period = data.period;
    const startDateControl = new FormControl<Instant | null>(period.range.start);
    const endDateControl = new FormControl<Instant | null>(period.range.end, greaterThanInstant(startDateControl));

    const tripRange = period.manualTripRange ?? data.automaticTripRange;
    const tripStartControl = new FormControl<Instant | null>(tripRange?.start ?? null, Validators.compose([
        greaterThanInstant(startDateControl),
        lessThanInstant(endDateControl),
        validateWhen(Validators.required, () => tripTimeType() === "manual")
    ]));
    const tripEndControl = new FormControl<Instant | null>(tripRange?.end ?? null, Validators.compose([
        greaterThanInstant(startDateControl),
        lessOrEqualThanInstant(endDateControl),
        greaterThanInstant(tripStartControl),
        validateWhen(Validators.required, () => tripTimeType() === "manual")
    ]));

    enableWhen(computed(() => tripTimeType() === "manual"), [tripStartControl, tripEndControl], injector);

    effect(() => {
        if (tripTimeType() === "automatic") {
            tripStartControl.reset(data.automaticTripRange?.start ?? null);
            tripEndControl.reset(data.automaticTripRange?.end ?? null);
        }

        tripStartControl.updateValueAndValidity();
        tripEndControl.updateValueAndValidity();

    }, {allowSignalWrites: true, injector});

    startDateControl.valueChanges.subscribe(() => {
        endDateControl.updateValueAndValidity();
        tripStartControl.updateValueAndValidity();
        tripEndControl.updateValueAndValidity();
    });

    endDateControl.valueChanges.subscribe(() => {
        tripStartControl.updateValueAndValidity();
        tripEndControl.updateValueAndValidity();
    });

    tripStartControl.valueChanges.subscribe(() => {
        tripEndControl.updateValueAndValidity();
    });

    return new FormGroup<TripFormControls>({
        type: new FormControl(taskTypes.find(t => t.type === period.type)!, {nonNullable: true}),
        startDate: startDateControl,
        endDate: endDateControl,
        tripStart: tripStartControl,
        tripEnd: tripEndControl,
        kilometers: new FormControl(period.kilometers),
        notes: new FormControl(period.notes, {nonNullable: true}),
        boatId: new FormControl(period.boatId)
    });
}

interface TripFormControls {
    type: FormControl<OperatorTaskTypeInfo>;
    startDate: FormControl<Instant | null>;
    endDate: FormControl<Instant | null>;
    tripStart: FormControl<Instant | null>;
    tripEnd: FormControl<Instant | null>;
    kilometers: FormControl<number | null>;
    notes: FormControl<string>;
    boatId: FormControl<number | null>;
}
