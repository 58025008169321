import { Observable, of as observableOf, OperatorFunction } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { comparators, comparing, formatInstantRange, formatLocalDate, formatTime, hasText, Instant, InstantRange, LocalDate, LocalDateRange, localDateTimeToInstant, toLocalDate, within24Hours, YearMonth } from "common";
import {
    OperatorId,
    OperatorRestViolationAcknowledgementData,
    OperatorRestViolationExplanationData,
    OperatorRestViolationReason,
    OperatorSecondmentId,
    OperatorSecondmentPeriodDto,
    OperatorTaskType,
    OperatorTimesheetAbsence,
    OperatorTimesheetEndpoint,
    OperatorTimesheetPeriodGroup,
    OperatorTimesheetRestPeriod,
    OperatorTimesheetRestViolation,
    OperatorTimesheetSecondmentInfo,
    OperatorTimesheetTotals,
    OperatorTimesheetWorkPeriod,
    OperatorWorkPeriodDto,
    RestMonitoringPeriodWithoutRest,
    SecondmentData,
    TimesheetPeriodErrors,
    VacationType,
    VesselMeetupInfo
} from "../operator-timesheet.service";
import { TranslateService } from "@ngx-translate/core";
import { MONITORING_PERIOD_WITHOUT_REST_LABEL, REST_VIOLATION_TYPE_NAME_KEY, restViolationReasonKey, SECONDMENT_ENDS_NOTE_KEY, SECONDMENT_NOTE_KEY, SECONDMENT_STARTS_NOTE_KEY, SECONDMENT_TYPE_NAME_KEY, vacationTypeKey } from "../translate/timesheet-translations";
import { getTaskDescriptionKey, REST_LABEL_KEY, REST_TYPE_NAME_KEY, TASK_ICONS, taskLocalizationKeys } from "../operator-task-utils";
import { signal } from "@angular/core";

export class OperatorTimesheetModel {

    private readonly _data = signal<AugmentedTimesheetData | "load-failed" | null>(null);

    readonly data = this._data.asReadonly();
    private readonly translations: { [key: string]: string };

    constructor(
        private readonly operatorTimesheetEndpoint: OperatorTimesheetEndpoint,
        private readonly translateService: TranslateService,
        public readonly operatorId: OperatorId,
        public readonly range: YearMonth | "recent",
    ) {

        this.translations = this.createTranslations();
        observableOf(null).pipe(this.refreshData()).subscribe({
            error: () => this._data.set("load-failed")
        });
    }

    createWorkPeriod(period: OperatorWorkPeriodDto): Observable<void> {
        return this.operatorTimesheetEndpoint.createWorkPeriod(this.operatorId, period)
            .pipe(this.refreshData());
    }

    updateWorkPeriod(periodId: number, period: OperatorWorkPeriodDto): Observable<void> {
        return this.operatorTimesheetEndpoint.updateWorkPeriod(periodId, period)
            .pipe(this.refreshData());
    }

    deleteWorkPeriod(periodId: number): Observable<void> {
        return this.operatorTimesheetEndpoint.deleteWorkPeriod(periodId)
            .pipe(this.refreshData());
    }

    createRestPeriod(range: InstantRange): Observable<void> {
        return this.operatorTimesheetEndpoint.createRestPeriod(this.operatorId, range)
            .pipe(this.refreshData());
    }

    updateRestPeriod(periodId: number, range: InstantRange): Observable<void> {
        return this.operatorTimesheetEndpoint.updateRestPeriod(periodId, range)
            .pipe(this.refreshData());
    }

    deleteRestPeriod(periodId: number): Observable<void> {
        return this.operatorTimesheetEndpoint.deleteRestPeriod(periodId)
            .pipe(this.refreshData());
    }

    updateSecondment(secondmentId: OperatorSecondmentId, secondment: SecondmentData): Observable<void> {
        return this.operatorTimesheetEndpoint.updateSecondment(secondmentId, secondment)
            .pipe(this.refreshData());
    }

    updateRestViolation(violationId: number, updateData: OperatorRestViolationExplanationData): Observable<void> {
        return this.operatorTimesheetEndpoint.updateRestViolation(violationId, updateData)
            .pipe(this.refreshData());
    }

    acknowledgeRestViolation(violationId: number, acknowledgeData: OperatorRestViolationAcknowledgementData): Observable<void> {
        return this.operatorTimesheetEndpoint.updateAndAcknowledgeRestViolation(violationId, acknowledgeData)
            .pipe(this.refreshData());
    }

    public refresh(): Observable<void> {
        return observableOf(null).pipe(this.refreshData());
    }

    private refreshData(): OperatorFunction<unknown, void> {
        const [year, month] = this.range === "recent" ? [null, null] : [this.range.year(), this.range.monthValue()];
        return switchMap(() => this.operatorTimesheetEndpoint.findTimesheetDays(this.operatorId, year, month).pipe(
            map(data => {
                const groups: PeriodGroupInfo[] = data.days.map(d => buildPeriodGroupInfo(d, this.translations));
                this._data.set({
                    totals: data.totals,
                    groups: groups,
                    valid: data.valid,
                    editableDays: data.editableDays,
                    absences: data.absences.map(x => ({
                        start: x.start,
                        end: x.end,
                        description: x.description,
                        days: x.days
                    })),
                    secondments: data.secondments,
                    timestamp: data.timestamp
                });
            })));
    }

    private createTranslations(): { [key: string]: string } {
        const timesheetKeyList = [
            REST_VIOLATION_TYPE_NAME_KEY, SECONDMENT_TYPE_NAME_KEY, SECONDMENT_NOTE_KEY,
            SECONDMENT_STARTS_NOTE_KEY, SECONDMENT_ENDS_NOTE_KEY, MONITORING_PERIOD_WITHOUT_REST_LABEL];

        const listOfKey = [
            ...Object.keys(OperatorRestViolationReason).map(reason => restViolationReasonKey(reason as OperatorRestViolationReason)),
            ...taskLocalizationKeys(), ...timesheetKeyList,
            ...Object.keys(VacationType).map(type => vacationTypeKey(type as VacationType))];

        return this.translateService.instant(listOfKey);
    }
}

export interface AugmentedTimesheetData {
    groups: PeriodGroupInfo[];
    totals: OperatorTimesheetTotals;
    valid: boolean;
    editableDays: LocalDateRange | null;
    secondments: OperatorTimesheetSecondmentInfo[];
    absences: OperatorTimesheetAbsence[];
    timestamp: Instant;
}

export interface PeriodGroupInfo {
    groupName: string;
    start: LocalDate;
    end: LocalDate;
    oneDayPeriod: boolean;
    isVacation: boolean;
    isPlannedShift: boolean;
    tyovuoroMerkki: string;
    tyovuoroMerkkiKuvaus: string;
    trainingMinutes: number;
    compensatedKilometers: number;
    uncompensatedKilometers: number;
    workMinutes: number;
    workMinutesWithinPeriodGroup: number;
    compensatedBoatMinutesWithinPeriodGroup: number;
    overtime50Minutes: number;
    overtime75Minutes: number;
    dailyAllowanceFull: number;
    dailyAllowancePart: number;
    shortTrips: number;
    longTrips: number;
    periods: PeriodInfo[];
    allPeriodNotes: string;
    hasCompensations: boolean;
    hasNotes: boolean;
    hasErrors: boolean;
    supervisorActionNeeded: boolean;
    editableRangeInGroup: LocalDateRange | null;
}

export enum PeriodType {
    WORK = "work",
    REST = "rest",
    SECONDMENT = "secondment",
    REST_VIOLATION = "rest_violation",
    NO_REST = "no_rest"
}

export interface PeriodInfo {
    id: number;
    icons: string[];
    label: string;
    type: PeriodType;
    typeName: string;
    range: InstantRange;
    tripRange?: InstantRange | null;
    tripStartImplicit?: boolean;
    hasManualTripRange?: boolean;
    notes: string;
    editable: boolean;
    removable: boolean;
    kilometers?: number | null;
    compensatedKilometers?: boolean;
    hideStart?: boolean;
    hideEnd?: boolean;
    hideDuration?: boolean;
    showDateWithStartTime: boolean;
    showDateWithEndTime: boolean;
    errors?: TimesheetPeriodErrors;
    taskType?: OperatorTaskType;
    partialRest?: boolean;
    supervisorActionNeeded: boolean;
    vesselMeetups?: VesselMeetupInfo[];
}

function buildPeriodGroupInfo(group: OperatorTimesheetPeriodGroup, translations: { [key: string]: string }): PeriodGroupInfo {
    const oneDayPeriod = group.start.equals(group.end);
    const editableDays = group.editableRangeInGroup;
    const periods = [
        ...worksToPeriods(editableDays, group.workPeriods, oneDayPeriod, translations),
        ...restsToPeriods(editableDays, group.restPeriods, oneDayPeriod, translations),
        ...secondmentsToPeriods(editableDays, group.secondmentPeriods, oneDayPeriod, group.start, translations),
        ...restViolationsToPeriods(editableDays, group.restViolations, oneDayPeriod, translations),
        ...noRestToPeriods(group.noRestPeriods, oneDayPeriod, translations)]
        .sort(comparators(comparing(r => r.range.start.toEpochMilli()), comparing(r => r.range.end.toEpochMilli())));

    const vacationType = group.vacationType;

    return {
        groupName: vacationType != null ? translations[vacationTypeKey(vacationType)] : "",
        editableRangeInGroup: group.editableRangeInGroup,
        start: group.start,
        end: group.end,
        oneDayPeriod: oneDayPeriod,
        isVacation: group.vacationType != null,
        isPlannedShift: group.plannedShift,
        tyovuoroMerkki: group.tyovuoroMerkki,
        tyovuoroMerkkiKuvaus: group.tyovuoroMerkkiKuvaus,
        trainingMinutes: group.trainingMinutes,
        compensatedKilometers: group.compensatedKilometers,
        uncompensatedKilometers: group.uncompensatedKilometers,
        workMinutes: group.workMinutes,
        workMinutesWithinPeriodGroup: group.workMinutesWithinPeriodGroup,
        compensatedBoatMinutesWithinPeriodGroup: group.compensatedBoatMinutesWithinPeriodGroup,
        overtime50Minutes: group.overtime50Minutes,
        overtime75Minutes: group.overtime75Minutes,
        shortTrips: group.shortTrips,
        longTrips: group.longTrips,
        dailyAllowanceFull: group.dailyAllowanceFull,
        dailyAllowancePart: group.dailyAllowancePart,
        periods,
        allPeriodNotes: periods.filter(x => hasText(x.notes)).map(x => x.notes).join(". "),
        hasCompensations: group.hasCompensations,
        hasNotes: hasNotes(group.workPeriods, group.restViolations),
        hasErrors: group.hasErrors,
        supervisorActionNeeded: isSupervisorActionNeeded(group.restViolations)
    };
}

function worksToPeriods(editableDays: LocalDateRange | null, works: OperatorTimesheetWorkPeriod[], oneDayPeriod: boolean, translations: { [key: string]: string }): PeriodInfo[] {
    return works.map(r => {
        const typeName = translations[getTaskDescriptionKey(r.type)];
        return {
            id: r.id,
            icons: TASK_ICONS[r.type],
            label: `${typeName} ${formatInstantRange(r.range)}`,
            type: PeriodType.WORK,
            typeName: typeName,
            range: r.range,
            tripRange: r.tripRange,
            tripStartImplicit: r.tripStartImplicit,
            hasManualTripRange: r.hasManualTripRange,
            kilometers: r.kilometers,
            compensatedKilometers: r.type === OperatorTaskType.CAR_TRIP_PILOTAGE,
            notes: r.notes,
            editable: isEditable(r.range.start, editableDays),
            removable: isEditable(r.range.start, editableDays),
            errors: r.errors,
            taskType: r.type,
            showDateWithStartTime: showDatePartForStart(oneDayPeriod),
            showDateWithEndTime: showDatePartForEnd(oneDayPeriod, false, r.range),
            supervisorActionNeeded: false,
            vesselMeetups: r.vesselMeetups
        };
    });
}

function isEditable(day: Instant, editableDays: LocalDateRange | null): boolean {
    return editableDays != null && editableDays.contains(toLocalDate(day));
}

function restsToPeriods(editableDays: LocalDateRange | null, rests: OperatorTimesheetRestPeriod[], oneDayPeriod: boolean, translations: { [key: string]: string }): PeriodInfo[] {
    return rests.map(r => {
        const restLabel = translations[REST_LABEL_KEY];
        const restTypeName = translations[REST_TYPE_NAME_KEY];
        return {
            id: r.id,
            icons: ['hotel'],
            label: `${restLabel} ${formatInstantRange(r.range)}`,
            type: PeriodType.REST,
            typeName: restTypeName,
            range: r.range,
            notes: '',
            editable: isEditable(r.range.start, editableDays),
            removable: isEditable(r.range.start, editableDays),
            errors: r.errors,
            showDateWithStartTime: showDatePartForStart(oneDayPeriod),
            showDateWithEndTime: showDatePartForEnd(oneDayPeriod, false, r.range),
            partialRest: r.partialRest,
            supervisorActionNeeded: false
        };
    });
}

function secondmentsToPeriods(editableDays: LocalDateRange | null, secondments: OperatorSecondmentPeriodDto[], oneDayPeriod: boolean, day: LocalDate, translations: { [key: string]: string }): PeriodInfo[] {
    const secondmentTypeName = translations[SECONDMENT_TYPE_NAME_KEY];

    return secondments.map(r => {
        const hideStart = !day.equals(r.startDate) || r.startTime == null;
        const range = r.estimatedRange;
        return ({
            id: r.id,
            icons: ['work'],
            label: secondmentLabel(r),
            type: PeriodType.SECONDMENT,
            typeName: secondmentTypeName,
            range: range,
            notes: secondmentNotes(day, r.startDate, r.endDate, r.stationName, translations),
            // TODO r.startDate should be joda localdate
            editable: isEditable(localDateTimeToInstant(r.startDate.atStartOfDay()), editableDays),
            removable: false,
            hideStart: hideStart,
            hideEnd: !day.isEqual(r.endDate) || r.endTime == null,
            showDateWithStartTime: showDatePartForStart(oneDayPeriod),
            showDateWithEndTime: showDatePartForEnd(oneDayPeriod, hideStart, range),
            supervisorActionNeeded: false
        });
    });
}

function showDatePartForStart(oneDayPeriod: boolean): boolean {
    return !oneDayPeriod;
}

function showDatePartForEnd(oneDayPeriod: boolean, hideStart: boolean, range: InstantRange): boolean {
    return !oneDayPeriod || (!hideStart && !within24Hours(range.start, range.end));
}

function restViolationsToPeriods(editableDays: LocalDateRange | null, violations: OperatorTimesheetRestViolation[], oneDayPeriod: boolean, translations: { [key: string]: string }): PeriodInfo[] {
    return violations.map(rv => {
        const reasonText = rv.reason !== null ? translations[restViolationReasonKey(rv.reason)] + ":" : "";
        const restViolationLabel = translations[REST_VIOLATION_TYPE_NAME_KEY];
        return {
            id: rv.id,
            icons: ['notification_important'],
            label: `${restViolationLabel} ${formatTime(rv.violationTime)}`,
            type: PeriodType.REST_VIOLATION,
            typeName: restViolationLabel,
            range: new InstantRange(rv.violationTime, rv.violationTime),
            notes: `${reasonText} ${rv.notes}`,
            editable: isEditable(rv.violationTime, editableDays),
            removable: false,
            hideStart: false,
            hideEnd: true,
            hideDuration: true,
            showDateWithStartTime: !oneDayPeriod,
            showDateWithEndTime: !oneDayPeriod,
            errors: rv.errors,
            supervisorActionNeeded: rv.acknowledgementNeeded
            };
        }
    );
}

function noRestToPeriods(noRestPeriods: RestMonitoringPeriodWithoutRest[], oneDayPeriod: boolean, translations: { [key: string]: string }): PeriodInfo[] {
    const label = translations[MONITORING_PERIOD_WITHOUT_REST_LABEL];
    return noRestPeriods.map(noRest => {
        return {
            id: 0,
            icons: ['notification_important'],
            label: label,
            type: PeriodType.NO_REST,
            typeName: label,
            range: noRest.range,
            notes: label,
            editable: false,
            removable: false,
            hideDuration: true,
            showDateWithStartTime: false,
            showDateWithEndTime: true,
            supervisorActionNeeded: false,
        };
    });
}

function hasNotes(workPeriods: OperatorTimesheetWorkPeriod[], restViolations: OperatorTimesheetRestViolation[]): boolean {
    return workPeriods.find(p => hasText(p.notes)) !== undefined ||
        restViolations.find(v => (v.reason != null || hasText(v.notes))) !== undefined;
}

function isSupervisorActionNeeded(restViolations: OperatorTimesheetRestViolation[]): boolean {
    return restViolations.find(v => v.acknowledgementNeeded) !== undefined;
}

function secondmentNotes(date: LocalDate, startDate: LocalDate, endDate: LocalDate, stationName: string, translations: { [key: string]: string }): string {
    if (startDate.equals(endDate)) {
        const note = translations[SECONDMENT_NOTE_KEY];
        return `${note} ${stationName}`;
    } else {
        const note = date.equals(startDate) ? translations[SECONDMENT_STARTS_NOTE_KEY] : translations[SECONDMENT_ENDS_NOTE_KEY];
        return `${note} ${stationName}`;
    }
}

function secondmentLabel(r: OperatorSecondmentPeriodDto): string {
    return `${r.stationName} ${formatDateTimeOrDate(r.startTime, r.startDate)}-${formatDateTimeOrDate(r.endTime, r.endDate)}`;
}

function formatDateTimeOrDate(dateTime: Instant | null, date: LocalDate): string {
    if (dateTime != null)
        return formatTime(dateTime);
    else
        return formatLocalDate(date);
}
