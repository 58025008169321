import { IconId } from "common";
import { OperatorTaskType } from "./operator-timesheet.service";

export const TASK_ICONS: Record<OperatorTaskType, IconId[]> = {
    BOAT_TRIP_PILOTAGE: ["directions_boat"],
    BOAT_TRIP_OTHER: ["directions_boat"],
    CAR_TRIP_PILOTAGE: ["directions_car", "transfer_within_a_station"],
    CAR_TRIP_OTHER: ["directions_car", "compare_arrows"],
    MAINTENANCE_EQUIPMENT: ["build"],
    MAINTENANCE_BUILDINGS: ["home"],
    NON_WORK_TRAVEL: ["departure_board"],
    OTHER_WORK: ["laptop_windows"],
    STATION_MEETING: ["group"],
};

type TaskDescriptionLocalizationKey = `timesheet.${Lowercase<OperatorTaskType>}`;

const TASK_DESCRIPTION_KEY: Record<OperatorTaskType, TaskDescriptionLocalizationKey> = {
    BOAT_TRIP_PILOTAGE: "timesheet.boat_trip_pilotage",
    BOAT_TRIP_OTHER: "timesheet.boat_trip_other",
    CAR_TRIP_PILOTAGE: "timesheet.car_trip_pilotage",
    CAR_TRIP_OTHER: "timesheet.car_trip_other",
    MAINTENANCE_EQUIPMENT: "timesheet.maintenance_equipment",
    MAINTENANCE_BUILDINGS: "timesheet.maintenance_buildings",
    NON_WORK_TRAVEL: "timesheet.non_work_travel",
    OTHER_WORK: "timesheet.other_work",
    STATION_MEETING: "timesheet.station_meeting",
};

export function getTaskDescriptionKey(task: OperatorTaskType): TaskDescriptionLocalizationKey {
    return TASK_DESCRIPTION_KEY[task];
}

export const REST_TYPE_NAME_KEY = "timesheet.rest";

export const REST_LABEL_KEY = "timesheet.resting";

export type TaskLocalizationKey = TaskDescriptionLocalizationKey | typeof REST_TYPE_NAME_KEY | typeof REST_LABEL_KEY;

/** Return array of all task related localization keys */
export function taskLocalizationKeys(): TaskLocalizationKey[] {
    const keys: TaskLocalizationKey[] = Object.keys(OperatorTaskType).map(task => getTaskDescriptionKey(task as OperatorTaskType));
    keys.push(REST_TYPE_NAME_KEY, REST_LABEL_KEY);
    return keys;
}
