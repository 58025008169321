<mat-dialog-content>
    @if (data.errors; as errors) {
        @if (errors.needsOvertimeNote) {
            <span class="error">{{ "timesheet.needs_overtime_note" | translate }} </span>
        }
        @if (errors.missingTripPeriod) {
            <span class="error">{{ "timesheet.missing_trip_period" | translate }} </span>
        }
        @if (errors.overlapping) {
            <span class="error">{{ "timesheet.overlap_warning" | translate }} </span>
        }
        @if (errors.needsRestViolationReason) {
            <span class="error">{{ "timesheet.rest_violation_needs_resolving" | translate }} </span>
        }
        @if (errors.hasWorkOnVacation) {
            <span class="error">{{ "timesheet.has_work_on_vacation" | translate }} </span>
        }
    }
    <span>{{ data.notes }}</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ "common.close" | translate }}</button>
</mat-dialog-actions>
