@if (ready()) {
    <h2 mat-dialog-title>{{ titleKey | translate }}</h2>
    <mat-dialog-content>

        <div class="form-container flex flex-col gap-2">
            <mat-form-field class="w-full">
                <mat-label>{{ 'timesheet.task_type' | translate }}</mat-label>
                <mat-select [formControl]="form.controls.type" [required]="true">
                    @for (type of taskTypes; track $index) {
                        <mat-option [value]="type">{{ type.name }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <div class="flex flex-col sm:flex-row gap-2 justify-between">
                <mat-form-field class="w-full">
                    <mat-label>{{ 'timesheet.period_start' | translate }}</mat-label>
                    <app-mat-date-time-picker [min]="selectableDays.min" [max]="selectableDays.max" [formControl]="form.controls.startDate" [required]="true"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>{{ 'timesheet.period_end' | translate }}</mat-label>
                    <app-mat-date-time-picker [min]="selectableDays.min" [max]="selectableDays.max" [formControl]="form.controls.endDate" [required]="true"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                    <mat-error>{{ 'timesheet.end_before_start_error' | translate }}</mat-error>
                </mat-form-field>
            </div>

            @if (showBoatSelection) {
                <div class="flex flex-col sm:flex-row gap-2 justify-between">
                    <mat-form-field class="w-full">
                        <mat-label translate>timesheet.boat</mat-label>
                        <mat-select [formControl]="form.controls.boatId">
                            @for (station of boatsInfo(); track $index) {
                                <mat-optgroup label="{{station.stationName}}">
                                    @for (boat of station.boats; track boat.id) {
                                        <mat-option [value]="boat.id">{{ boat.name }}
                                            @if (boat.nickName; as nickName) {
                                                <span> - {{ nickName }}</span>
                                            }
                                        </mat-option>
                                    }
                                </mat-optgroup>
                            }
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Matka-aika</mat-label>
                        <mat-select [formControl]="tripTimeType">
                            <mat-option value="automatic">{{ 'timesheet.automatic' | translate }}</mat-option>
                            <mat-option value="manual">{{ 'timesheet.manual' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            }

            @if (showTripRange) {
                <div class="flex flex-col sm:flex-row gap-2 justify-between">
                    <mat-form-field class="w-full">
                        <mat-label>{{ 'timesheet.trip_start' | translate }}</mat-label>
                        <app-mat-date-time-picker [min]="selectableDays.min" [max]="selectableDays.max" [startAt]="tripStartInitialPickerValue" [formControl]="form.controls.tripStart"></app-mat-date-time-picker>
                        <mat-icon matIconSuffix>calendar_today</mat-icon>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>{{ 'timesheet.trip_end' | translate }}</mat-label>
                        <app-mat-date-time-picker [min]="selectableDays.min" [max]="selectableDays.max" [startAt]="tripEndInitialPickerValue" [formControl]="form.controls.tripEnd"></app-mat-date-time-picker>
                        <mat-icon matIconSuffix>calendar_today</mat-icon>
                    </mat-form-field>
                </div>
            }

            @if (showKilometers) {
                <mat-form-field class="w-full">
                    <mat-label>{{ 'timesheet.trip_km' | translate }}</mat-label>
                    <input type="number" matInput [formControl]="form.controls.kilometers">
                    <span matTextSuffix>km</span>
                </mat-form-field>
            }

            <mat-form-field class="w-full">
                <mat-label>{{ 'timesheet.notes' | translate }}</mat-label>
                <textarea matInput [formControl]="form.controls.notes" rows="5"></textarea>
            </mat-form-field>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ "common.cancel" | translate }}</button>
        <app-spinner-button (spinnerClick)="save()" [active]="saving()" [disabled]="form.invalid">{{ "common.save" | translate }}</app-spinner-button>
    </mat-dialog-actions>
} @else {
    <mat-spinner class="default-spinner" />
}
